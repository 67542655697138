import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Box, Typography, Avatar } from "@mui/material";

import { useSoulChatStore } from "../../../stores/SoulChatStore";
import ChatIcon from "./svg/chat";
import { Chat } from "../../../api/create/CreateApi";

export default function ChatHistory({
  className,
  onClose,
}: {
  className?: string;
  onClose?: () => void;
}) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get("id");
  const { historyChatList, cleanUpOpt, currentChat, chatDetail } =
    useSoulChatStore((state) => ({
      historyChatList: state.historyChatList,
      cleanUpOpt: state.cleanUpOpt,
      currentChat: state.currentChat,
      chatDetail: state.chatDetail,
    }));

  const judgeIsPhoto = (message: string) => {
    if (message) {
      return message.startsWith("https") ? "[Photo]" : message;
    }
    return "";
  };
  // 获取最新消息
  const getLatestMessage = (item: Chat) => {
    if (item.id !== chat_id) {
      return judgeIsPhoto(item?.latest_message || "");
    }

    if (currentChat?.chat_id === chat_id && chatDetail.length > 0) {
      const lastMessage = chatDetail
        ?.slice()
        ?.reverse()
        ?.find((msg) => msg?.content)?.content;

      if (lastMessage) {
        return judgeIsPhoto(lastMessage);
      }
    }

    return judgeIsPhoto(item?.latest_message || "");
  };
  return (
    <Box
      sx={{
        width: 280,
        borderRight: "1px solid rgba(255,255,255,0.1)",
        flexDirection: "column",
        maxHeight: {
          sm: "calc(100vh - 120px)",
        },
      }}
      className={className}
    >
      <Typography
        sx={{
          p: 4,
          display: "flex",
          alignItems: "center",
          gap: 1,
        }}
      >
        <ChatIcon />
        <span className="font-bold">Chat History</span>
      </Typography>

      <Box sx={{ flex: 1, overflowY: "auto", px: 2 }}>
        {historyChatList.map((item) => (
          <Box
            key={item.id}
            sx={{
              py: 1,
              px: 2,
              display: "flex",
              alignItems: "center",
              textAlign: "left",
              borderRadius: 4,
              gap: 2,
              "&:hover": {
                bgcolor: "rgba(255,255,255,0.1)",
                cursor: "pointer",
              },
              backgroundColor:
                chat_id === item.id ? "rgba(255,255,255,0.1)" : "transparent",
            }}
            onClick={() => {
              onClose?.();
              cleanUpOpt();
              navigate(`/ai-chat?id=${item.id}`);
            }}
          >
            <Avatar src={item.icon} />
            <Box>
              <Typography variant="subtitle1">{item.bot_name}</Typography>
              <Typography
                variant="body2"
                sx={{ opacity: 0.7 }}
                className="line-clamp-1"
              >
                {getLatestMessage(item)}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
}
