import { useCreateStores } from "../../stores/CreateStores";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faCircleQuestion,
} from "@fortawesome/free-solid-svg-icons";
import { FaceModelInfoDialog } from "./FaceModelInfoDialog";
import { LoadingDialog } from "./LoadingDoialog";
import { useUserStores } from "../../stores/UserStores";
import { useNavigate } from "react-router-dom";
import { OnboardingDialog } from "./OnboardingDialog";
import { useTranslation } from "react-i18next";

export function ModelSelector() {
  const {
    createEntity,
    model,
    setModel,
    uploadFace,
    faces,
    uploadingFaceModel,
    queryAnalysisResult,
    showFaceModelInfo,
    hideFaceModelInfo,
    showFaceModelInfoDialog,
    showUploadingDialog,
    hideOnboardingDialog,
    showOnboarding,
    uploadingDialogTitle,
    cancelFaceModel,
    showHint,
  } = useCreateStores((state) => ({
    createEntity: state.createEntity,
    model: state.configEntity.model,
    setModel: state.setModel,
    uploadFace: state.uploadFace,
    faces: state.faces,
    uploadingFaceModel: state.uploadingFaceModel,
    queryAnalysisResult: state.queryAnalysisResult,
    showFaceModelInfo: state.showFaceModelInfo,
    showOnboarding: state.showOnboarding,
    hideFaceModelInfo: state.hideFaceModelInfo,
    showFaceModelInfoDialog: state.showFaceModelInfoDialog,
    hideOnboardingDialog: state.hideOnboardingDialog,
    showUploadingDialog: state.showUploadingDialog,
    uploadingDialogTitle: state.uploadingDialogTitle,
    cancelFaceModel: state.cancelFaceModel,
    showHint: state.showHint,
  }));

  const { userInfo } = useUserStores((state) => ({
    userInfo: state.userEntity,
  }));

  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const fileInputRef = React.createRef<HTMLInputElement>();

  useEffect(() => {
    setInputRef(fileInputRef.current); // 使用 current 属性
  }, []);

  useEffect(() => {
    if (uploadingFaceModel) {
      queryAnalysisResult();
    }
  }, [uploadingFaceModel]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!["image/jpeg", "image/png"].includes(files[0].type)) {
        showHint("Only jpg/png format images are allowed for upload.");
        return; // 终止执行
      }
      if (files[0].size > 2 * 1024 * 1024) {
        showHint("The file size cannot exceed 2MB, please choose again.");
        return; // 终止执行
      }
      uploadFace(files[0]);
    }
  };
  const navigation = useNavigate();
  const clickUpload = () => {
    if (
      userInfo &&
      userInfo.entitlement &&
      userInfo.entitlement.member_level &&
      userInfo.entitlement.member_level[0] > 0
    ) {
      fileInputRef.current?.click();
    } else {
      navigation("/subscribe");
    }
  };
  const hideIngoDialog = () => {
    hideFaceModelInfo();
  };
  const cancelQuery = () => {
    cancelFaceModel();
  };
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-between  w-full  h-500 border-gray border-2 rounded-[10px]  mt-[15px]">
      <FaceModelInfoDialog
        show={showFaceModelInfoDialog}
        msg={""}
        onCancel={() => {
          hideIngoDialog();
        }}
      ></FaceModelInfoDialog>

      <OnboardingDialog
        show={showOnboarding}
        msg={""}
        onCancel={() => {
          hideOnboardingDialog();
        }}
      ></OnboardingDialog>
      <LoadingDialog
        show={showUploadingDialog}
        title={uploadingDialogTitle}
        msg={""}
        onCancel={() => {
          cancelQuery();
        }}
      ></LoadingDialog>

      <div
        className="flex flex-row  justify-start items-center bg-transparent  bg-dark-bg ml-[10px] mt-[8px] "
        onClick={() => {
          showFaceModelInfo();
        }}
      >
        <div className="text-white font-bold text-[20px] select-none">
          {t("looks_like")}
        </div>
        <div className="text-gray-d text-[15px] ml-[4px] ">(optional)</div>
        <FontAwesomeIcon
          icon={faCircleQuestion}
          size={"sm"}
          color="white"
          className="ml-[3px]"
        ></FontAwesomeIcon>
      </div>
      <div className="flex overflow-x-auto scroll-snap-type-x mt-[8px] pb-[8px]  w-max-[300px] scrollbar-track-transparent scrollbar-thumb-blue-500 items-center">
        <div className="relative flex flex-row  items-end w-[70px] h-[70px] border-gary border-2 rounded-[35px] bg-gray justify-center items-center text-white scroll-snap-align-start ml-[10px]">
          <div className="h-[1px] text-transparent  w-[69px]"></div>
          <input
            ref={fileInputRef}
            type="file"
            onChange={handleChange}
            className="w-[100%] h-[100%] bg-transparent outline-0  focus:outline-none focus:shadow-outline shadow-transparent invisible"
            accept="image/jpeg,image/png"
            placeholder=""
          ></input>
          <div
            className="absolute w-[100%] h-[100%] flex justify-center items-center"
            onClick={clickUpload}
          >
            <FontAwesomeIcon
              icon={faArrowUpFromBracket}
              size={"lg"}
              color={"white"}
            />
          </div>
          <div className="absolute top-[-2px] right-[-10px]  bg-red-500 h-[20px] rounded-[12.5px] text-white text-[12px] font-bold pl-2 pr-2">
            Pro
          </div>
        </div>
        {faces &&
          faces.map((item, index) => {
            const selected = index == createEntity.faceModel.index;
            return (
              <div
                className="w-[69px] h-[69px] scroll-snap-align-start ml-[10px]"
                onClick={() => setModel(item)}
              >
                <div className="h-[1px] text-transparent ">123123123</div>
                <img
                  className={`border-${selected ? "primary-color" : "gray"} border-[2px] rounded-[35px]  w-[69px] h-[69px] scroll-snap-align-start`}
                  src={item.resize_url}
                ></img>
              </div>
            );
          })}
      </div>
    </div>
  );
}
