import { t } from "i18next";
import React from "react";
import { Box, Modal, Button as MuiButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const SubscribeModal = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
  const navigate = useNavigate();
  return (
    <Modal open={open}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "#1E1F24",
          borderRadius: 3,
          boxShadow: "0 8px 32px rgba(0, 0, 0, 0.4)",
          p: {
            xs: 3,
            sm: 4,
          },
          color: "#fff",
          border: "1px solid rgba(255, 255, 255, 0.1)",
        }}
      >
        <div className="sm:text-2xl text-xl font-bold mb-4 text-white">
          Please subscribe to continue
        </div>

        <div className="sm:text-base text-sm  mb-6 text-gray-300">
          You don't have enough credits
        </div>

        <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
          <MuiButton
            sx={{
              color: "rgba(255, 255, 255, 0.7)",
              "&:hover": {
                backgroundColor: "rgba(255, 255, 255, 0.1)",
              },
              fontSize: {
                xs: "12px",
                sm: "14px",
              },
            }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </MuiButton>
          <MuiButton
            variant="contained"
            sx={{
              backgroundColor: "#6C5DD3",
              "&:hover": {
                backgroundColor: "#5648B2",
              },
              textTransform: "none",
              borderRadius: 1.5,
              px: 3,
            }}
            onClick={() => navigate("/subscribe")}
          >
            {t("subscribe")}
          </MuiButton>
        </Box>
      </Box>
    </Modal>
  );
};

export default SubscribeModal;
