import React, { useEffect } from "react";
import { Button } from "@material-tailwind/react";
import { CircularProgress } from "@mui/material";
import { t } from "i18next";
import toast from "react-hot-toast";

import { TaskType } from "../../../model/create/CreateEntity";
import { useSoulChatStore } from "../../../stores/SoulChatStore";
import { useCreateStores } from "../../../stores/CreateStores";
import { APP_ID_DELORIS, COST_TYPE } from "../../../constant";
import { useUserStores } from "../../../stores/UserStores";
import SubscribeModal from "../SubscribeModal";
import { firebaseLogEvent } from "../../../util/utils";
import { EVENT_ACTION_SOULMATES_CREATE } from "../../../util/Event";

export default function CreateButton() {
  const [open, setOpen] = React.useState(false);
  const {
    createPicID,
    addSoulmateDialogData,
    customCharacter,
    createProcessLoading,
    getPicByID,
    template_id,
  } = useSoulChatStore((state) => ({
    createPicID: state.createPicID,
    addSoulmateDialogData: state.addSoulmateDialogData,
    customCharacter: state.customCharacter,
    createProcessLoading: state.createProcessLoading,
    getPicByID: state.getPicByID,
    template_id: state.template_id,
  }));
  const {
    soulmate_name,
    selectedFace,
    selectedOtherTags,
    voice_id,
    soulmate_age,
    personalityAndHobbiesTags,
    group_id,
  } = addSoulmateDialogData;

  const { createEntity } = useCreateStores((state) => ({
    createEntity: state.createEntity,
  }));
  const { userEntity } = useUserStores((state) => ({
    userEntity: state.userEntity,
  }));

  useEffect(() => {
    if (template_id) {
      // 改成串行，需要返回template_id后，再调用生成四张图片
      // 去生成四张图片
      createPicID({
        project_name: "123123",
        project_novel_text: selectedOtherTags.join(","),
        project_type: TaskType.image,
        project_config: {
          sd_model_name: createEntity.model.model_name,
          sd_image_ratio: "2:3",
          batch_number: 4,
          lora_model_name: createEntity.model.name,
          face_model_id: selectedFace?.id ?? null,
          voice_name: "",
          voice_speed: 1,
          voice_volume: 11,
          background_music_url: "",
          bot_template_id: template_id,
        },
        type: COST_TYPE.CUSTOM_CHARACTER,
      }).then((pic_id) => {
        getPicByID(pic_id);
      });
    }
  }, [template_id]);

  const handleCreateClick = async () => {
    if ((userEntity?.entitlement?.credit_point ?? 0) < 10) {
      setOpen(true);
      return;
    }
    if (!soulmate_name || !selectedFace?.id) {
      toast.error("The face and name fields are required");
      return;
    }

    if (selectedOtherTags.join("").length < 20) {
      toast.error(
        "The content word count needs to be within the range of 20 to 3000."
      );
      return;
    }
    firebaseLogEvent(EVENT_ACTION_SOULMATES_CREATE);
    // 创建角色,获取id和message_id
    customCharacter({
      icon: selectedFace?.url,
      app_id: APP_ID_DELORIS,
      description: "",
      sys_prompt: "",
      provider: "aimlapi",
      model_name: "meta-llama/Llama-3.3-70B-Instruct-Turbo",
      group_id,
      bot_name: soulmate_name,
      is_system_template: false,
      face_model_id: selectedFace?.id,
      character_tags: {
        age: soulmate_age,
        personality: personalityAndHobbiesTags
          .filter((tag) => tag.includes("personality__"))
          .map((tag) => tag.split("__")[1]),
        hobbies: personalityAndHobbiesTags
          .filter((tag) => tag.includes("hobbies__"))
          .map((tag) => tag.split("__")[1]),
      },
      voice_id,
    });
  };
  return (
    <>
      <Button
        placeholder={""}
        onClick={handleCreateClick}
        disabled={createProcessLoading}
        className="relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center sm:text-[22px] text-base bg-gray w-full  h-500 border-gray border-4 rounded-[30px] h-[45px] sm:h-[60px] sm:mt-[30px] mt-3"
      >
        <div>{t("create")}</div>
        <div className="absolute right-5 top-0 text-[12px] bg-white text-black pl-2 pr-2  rounded-bl-[10px] rounded-br-[10px] normal-case">
          {10} {t("credits")}
        </div>
        {createProcessLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "white",
              ml: 2,
            }}
          />
        )}
      </Button>
      {open && <SubscribeModal open={open} setOpen={setOpen} />}
    </>
  );
}
