import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
const SubscriptionCard = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        px: {
          xs: 1,
          sm: 3,
        },
        maxWidth: 400,
        mx: "auto",
      }}
    >
      <div className="text-sm sm:text-xl text-left mb-2 sm:mb-4">
        The free quota has been used up
      </div>

      <Button
        variant="contained"
        sx={{
          bgcolor: "#9170f0",
          "&:hover": {
            bgcolor: "#7857d8",
          },
        }}
        onClick={() => {
          navigate("/subscribe");
        }}
      >
        <span className="text-xs sm:text-sm">Subscribe now</span>
      </Button>
    </Box>
  );
};

export default SubscriptionCard;
