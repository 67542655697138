import { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { AUTO_PLAY_KEY } from "../../../constant";

export default function AutoPlayGuide({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  useEffect(() => {
    const isAutoPlaying = localStorage.getItem(AUTO_PLAY_KEY);
    if (isAutoPlaying === null) {
      setOpen(true);
    }
  }, []);

  const handleChoice = (choice: boolean) => {
    localStorage.setItem(AUTO_PLAY_KEY, String(choice));
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          bgcolor: "#1b1c2c",
          color: "white",
          borderRadius: 2,
        },
      }}
    >
      <DialogTitle sx={{ textAlign: "center" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: 1,
          }}
        >
          <VolumeUpIcon />
          <Typography variant="h6">Auto Play Settings</Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ textAlign: "center", my: 2 }}>
          Would you like to enable auto-play for audio? You can change this
          setting anytime.
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", pb: 3 }}>
        <Button
          variant="outlined"
          onClick={() => handleChoice(false)}
          sx={{
            color: "white",
            borderColor: "rgba(255,255,255,0.3)",
            "&:hover": {
              borderColor: "white",
            },
          }}
        >
          Disable Auto Play
        </Button>
        <Button
          variant="contained"
          onClick={() => handleChoice(true)}
          sx={{
            bgcolor: "#9170f0",
            "&:hover": {
              bgcolor: "#917fff",
            },
          }}
        >
          Enable Auto Play
        </Button>
      </DialogActions>
    </Dialog>
  );
}
