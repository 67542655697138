import React, { useState } from "react";
import { VideoContent } from "./VideoContent";
import { VideoFaceModelList } from "./VideoFaceModelList";
import { VideoProjectList } from "./VideoProjectList";
import { useParams } from "react-router-dom";
import i18n from "../../react-i18next/i18n";

//flex demo

export function FaceEdit() {
  const { lng } = useParams();
  i18n.changeLanguage(lng);
  return (
    <div className="h-screen overflow-y-auto bg-slate-950 no-scrollbar flex-row pb-[20px]">
      <div className="flex lg:flex-row flex-col justify-content-center h-full  w-full pt-1 pl-2 pr-2 lg:pl-[2%] lg:pr-[2%] lg:mt-4">
        <VideoProjectList />
        <VideoContent />
        <VideoFaceModelList />
      </div>
    </div>
  );
}
