import { TaskProcessBar } from "./TaaskProcessBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { userTaskStores } from "../../stores/TaskStores";
import { useTranslation } from "react-i18next";

interface IProps {
  duration: number;
}

export function GeneratingView(props: IProps) {
  const { duration } = props;
  const { confirmContent } = userTaskStores((state) => ({
    confirmContent: state.confirmContent,
  }));

  const gotoGenerated = () => {
    confirmContent();
  };
  const { t } = useTranslation();
  return (
    <div className="absolute bg-black w-full h-full flex flex-col justify-center items-center rounded-[20px]">
      <div className="flex flex-col ">
        <TaskProcessBar duration={duration} />
        <div className="text-white text-[22px] mt-[10px]">
          {t("your_creation_is_processing")}
        </div>
      </div>
      <div
        onClick={gotoGenerated}
        className="cursor-pointer select-none pl-[10px]  pr-[10px] bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray   h-500 border-gray border-2 rounded-[10px] h-[50px] mt-[230px]"
      >
        <div className="text-white">{t("get_priority_queue")}</div>
        <FontAwesomeIcon
          className="ml-[10px]"
          size={"lg"}
          color="white"
          icon={faArrowRight}
        />
      </div>
    </div>
  );
}
