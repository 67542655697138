import {
  CodeResponse,
  CredentialResponse,
  GoogleLogin,
  useGoogleLogin,
  useGoogleOneTapLogin,
} from "@react-oauth/google";
import { Button, Spinner } from "@material-tailwind/react";
import { useUserStores } from "../../stores/UserStores";
import { AuthRequest } from "../../api/user/UserApi";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { PageLoading } from "../../components/common/PageLoading";
import { GoogleLoginBtn } from "../../components/Login/GoogleLoginBtn";
import { AppleLoginBtn } from "../../components/Login/AppleLoginBtn";
import { logEvent } from "firebase/analytics";
import { analytics, SOURCE_ID_KEY } from "../../App";
import { EVENT_PAGE_CREATE, EVENT_PAGE_LOGIN } from "../../util/Event";
import i18n from "../../react-i18next/i18n";

export function Login() {
  const { lng } = useParams();
  i18n.changeLanguage(lng);
  const { auth, userEntity, loading } = useUserStores((state) => ({
    auth: state.authGoogle,
    userEntity: state.userEntity,
    loading: state.loading,
  }));
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  let mode = searchParams.get("mode") ? searchParams.get("mode") : "login";

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log("useGoogleLogin " + JSON.stringify(tokenResponse));
      auth(tokenResponse.access_token);
    },
    flow: "implicit",
  });

  useEffect(() => {
    const sourceId = localStorage.getItem(SOURCE_ID_KEY);
    logEvent(analytics, EVENT_PAGE_LOGIN, {
      page_path: EVENT_PAGE_LOGIN,
      source_id: sourceId,
    });
  }, []);

  useEffect(() => {
    // 需要使用参数，这样来校验到底是哪边跳过来的。
    if (userEntity) {
      if (origin === "face_edit_page") {
        navigate("/face-edit");
      } else if (origin === "create_page") {
        navigate("/create");
      } else if (origin === "soul_chat_page") {
        navigate("/soulChat");
      }
    }
  }, [userEntity]);

  const loginMode = () => {
    if (mode && mode === "login") {
      return true;
    }
    return false;
  };
  const location = useLocation();
  const { origin } = location.state || { origin: "soul_chat_page" };
  console.log("origin = ", origin);
  return (
    <div className="bg-dark-bg select-none h-full w-full text-white text-[30px] flex flex-row justify-center lg:justify-start items-center">
      <div className="w-[0px] lg:w-[50%] relative h-full">
        <img
          src={require("../../../src/assets/login_bg.webp")}
          className="w-full h-full blur-md object-cover"
        ></img>
        <div className="text-transparent lg:text-white absolute font-bold inset-0 flex flex-col items-center justify-center ">
          Deloris
          <div className="text-[12px]">Create your own girlfriend</div>
        </div>
      </div>
      <div className="text-[10px] flex-1  max-w-[80%] lg:max-w-[70%] lg:w-[70%] justify-center items-center flex flex-col gap-10">
        {loading && (
          <Spinner
            className="h-50 w-50 text-gray-900/50 mr-[10px]"
            color="purple"
          />
        )}
        <div className="flex flex-col items-start w-full  lg:w-[70%] font-bold">
          <div className="text-white text-[30px] select-none">
            {loginMode() ? "Login" : "Sign up"}
          </div>
          <div className="flex flex-row text-[16px] font-normal">
            <div className="text-gray-d">
              {loginMode() ? "New User?" : "Already have an account?"}
            </div>
            <div
              onClick={() => {
                if (loginMode()) {
                  navigate("login?mode=register");
                } else {
                  navigate("login?mode=login");
                }
              }}
              className="cursor-pointer text-primary-color ml-[10px]"
            >
              {loginMode() ? "Sign up" : "Log in"}
            </div>
          </div>
        </div>
        <div className="w-[100%] lg:w-[70%] flex flex-col justify-start gap-10">
          <GoogleLoginBtn
            onClick={() => {
              login();
            }}
          ></GoogleLoginBtn>
          <AppleLoginBtn onClick={login}></AppleLoginBtn>
        </div>
      </div>
    </div>
  );
}
