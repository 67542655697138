/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  Box,
  Typography,
  IconButton,
  InputBase,
  Avatar,
  Chip,
  Drawer,
  Tooltip,
} from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SendIcon from "@mui/icons-material/Send";
import ImageIcon from "@mui/icons-material/Image";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useSoulChatStore } from "../../stores/SoulChatStore";
import ThreeDotsLoading from "../../components/SoulChat/ThreeDotsLoading";
import ImageCarousel from "../../components/SoulChat/ImageCarousel";
import MoreOperate from "../../components/SoulChat/MoreOperate";
import useResponsive from "../../hooks/useResponsive";
import ChatHistory from "../../components/SoulChat/ChatHistory";
import AutoPlayGuide from "../../components/SoulChat/AutoPlayGuide";
import { AUTO_PLAY_KEY, COST_TYPE } from "../../constant";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { TaskType } from "../../model/create/CreateEntity";
import { useCreateStores } from "../../stores/CreateStores";
import toast from "react-hot-toast";
import { useUserStores } from "../../stores/UserStores";
import SubscribeModal from "../../components/SoulChat/SubscribeModal";
import { debounce } from "lodash";
import SubscriptionCard from "../../components/SoulChat/SubscriptionCard";
import {
  EVENT_ACTION_PAGE_AI_CHAT,
  EVENT_ACTION_SOULMATES_SEND_CHAT_MESSAGE,
  EVENT_ACTION_SOULMATES_SEND_PIC_MESSAGE,
} from "../../util/Event";
import { firebaseLogEvent } from "../../util/utils";

export default function SoulChat() {
  const [searchParams] = useSearchParams();
  const chat_id = searchParams.get("id");
  const [msg, setMsg] = useState<string>("");
  const [photoOf, setPhotoOf] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [showRightPanel, setShowRightPanel] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const { isXLargeScreen } = useResponsive();
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false);
  const {
    historyChatList,
    getHistoryChatList,
    getChatDetail,
    chatDetail,
    sendMessage,
    currentChat,
    getGreetings,
    canSendMessage,
    setHistoryChatList,
    deleteChatHistory,
    playingMessageId,
    loadingMessageId,
    playSingleMessageVoice,
    isPlaying,
    initAudio,
    currentAudio,
    cleanUpOpt,
    face_model_id,
    pic_generate_loading,
    isLoadingMore,
    handleLoadMore,
    isCanScroll,
  } = useSoulChatStore((state) => ({
    historyChatList: state.historyChatList,
    getHistoryChatList: state.getHistoryChatList,
    getChatDetail: state.getChatDetail,
    chatDetail: state.chatDetail,
    sendMessage: state.sendMessage,
    currentChat: state.currentChat,
    getGreetings: state.getGreetings,
    canSendMessage: state.canSendMessage,
    setHistoryChatList: state.setHistoryChatList,
    deleteChatHistory: state.deleteChatHistory,
    playingMessageId: state.playingMessageId,
    loadingMessageId: state.loadingMessageId,
    playSingleMessageVoice: state.playSingleMessageVoice,
    initAudio: state.initAudio,
    isPlaying: state.isPlaying,
    currentAudio: state.currentAudio,
    cleanUpOpt: state.cleanUpOpt,
    face_model_id: state.face_model_id,
    pic_generate_loading: state.pic_generate_loading,
    isLoadingMore: state.isLoadingMore,
    handleLoadMore: state.handleLoadMore,
    isCanScroll: state.isCanScroll,
  }));
  const { createEntity } = useCreateStores((state) => ({
    createEntity: state.createEntity,
  }));
  const { userEntity, fetchUserInfo } = useUserStores((state) => ({
    userEntity: state.userEntity,
    fetchUserInfo: state.fetchUserInfo,
  }));

  const autoPlay = localStorage.getItem(AUTO_PLAY_KEY) === "true";

  useEffect(() => {
    if (!chat_id) {
      navigate("/soulChat");
      return;
    }
    firebaseLogEvent(EVENT_ACTION_PAGE_AI_CHAT);
    fetchUserInfo();
    if (!currentAudio) {
      initAudio();
    }
    return () => {
      cleanUpOpt();
    };
  }, []);

  useEffect(() => {
    if (chat_id) {
      getHistoryChatList();
      getChatDetail(chat_id);
    }
  }, [chat_id]);

  useEffect(() => {
    if (!chatDetail.length && currentChat?.id) {
      getGreetings(chat_id || "");
    }
  }, [chatDetail, currentChat, chat_id]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (isCanScroll) scrollToBottom();
  }, [chatDetail, isCanScroll]);

  const handleScroll = debounce((target: HTMLDivElement) => {
    if (target.scrollTop <= 0 && chat_id) {
      handleLoadMore(chat_id);
    }
  }, 200);

  const handleSendMessage = () => {
    if (!msg.trim() || !canSendMessage) return;
    handleChangeOrder();
    if (photoOf) {
      if ((userEntity?.entitlement?.credit_point ?? 0) < 2) {
        setOpenSubscribeModal(true);
        return;
      }
      if (msg.length < 20) {
        toast.error("Please enter at least 20 characters");
        return;
      }
      firebaseLogEvent(EVENT_ACTION_SOULMATES_SEND_PIC_MESSAGE);
      const picParams = {
        project_name: "123123",
        project_novel_text: "Photo of " + msg,
        project_type: TaskType.imageInChat,
        project_config: {
          sd_model_name: createEntity.model.model_name,
          sd_image_ratio: "2:3",
          batch_number: 1,
          lora_model_name: createEntity.model.name,
          face_model_id: currentChat?.face_model_id || face_model_id,
          voice_name: "",
          voice_speed: 1,
          voice_volume: 11,
          background_music_url: "",
          face_url: currentChat?.icon,
          chat_id: chat_id || "",
          bot_template_id: currentChat?.id,
        },
        type: COST_TYPE.IMAGE_IN_CHAT,
      };
      sendMessage(
        {
          content: "Photo of " + msg,
          chat: chat_id || "",
        },
        true,
        picParams
      );
    } else {
      firebaseLogEvent(EVENT_ACTION_SOULMATES_SEND_CHAT_MESSAGE);
      sendMessage({
        content: msg,
        chat: chat_id || "",
      });
    }
    setMsg("");
  };

  // 开始聊天后改变左边列表的顺序，正在聊天的排在最前面
  const handleChangeOrder = () => {
    const currentItem = historyChatList.find((item) => item.id === chat_id);
    if (!currentItem) return;

    const newHistoryChatList = historyChatList.filter(
      (item) => item.id !== chat_id
    );
    newHistoryChatList.unshift(currentItem);
    setHistoryChatList(newHistoryChatList);
  };

  return (
    <Box
      sx={{
        display: "flex",
        height: {
          lg: "calc(100vh - 60px)",
          xs: "calc(var(--vh, 1vh) * 100)",
        },
        bgcolor: "#1b1c2c",
        color: "white",
      }}
      className="m-auto w-full max-w-[1440px] xl:rounded-3xl xl:mb-5"
    >
      {/* Left Sidebar - Chat History */}
      <ChatHistory className="xl:flex hidden" />

      {/* Main Chat Area */}
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
        {/* Chat Header */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
          }}
          className="h-14 px-2 sm:px-4 sm:h-[75px]"
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
            <div className="xl:hidden block cursor-pointer">
              <ArrowBackIosIcon
                onClick={() => {
                  navigate("/soulChat");
                }}
              />
            </div>
            <Avatar src={currentChat?.icon} key={currentChat?.icon} />
            <Typography variant="h6">{currentChat?.title}</Typography>
          </Box>
          <Box>
            <IconButton
              sx={{
                color: "#9170f0",
                mr: 1,
                "&:hover": {
                  color: autoPlay ? "#9170f0" : "white",
                },
              }}
              onClick={() => {
                setOpen(true);
              }}
              title={autoPlay ? "Disable auto-play" : "Enable auto-play"}
            >
              {autoPlay ? (
                <VolumeUpIcon sx={{ color: "primary.main" }} />
              ) : (
                <VolumeOffIcon />
              )}
            </IconButton>
            <MoreOperate
              onClearChats={() => {
                deleteChatHistory(chat_id || "");
              }}
            />
            <IconButton
              sx={{ color: "white" }}
              onClick={() => {
                if (isXLargeScreen) {
                  setShowDrawer(true);
                } else {
                  setShowRightPanel(!showRightPanel);
                }
              }}
            >
              <MenuOpenIcon
                sx={{
                  transform:
                    showRightPanel || showDrawer ? "rotate(180deg)" : "none",
                  transition: "transform 0.3s",
                }}
              />
            </IconButton>
          </Box>
        </Box>

        {/* Messages Area */}
        <Box
          sx={{
            flex: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          // className="p-4 sm:p-6 sm:max-h-[calc(calc(var(--vh, 1vh) * 100)-4rem-75px)] xl:max-h-[calc(calc(var(--vh, 1vh) * 100)-60px-4rem-75px)] max-h-[calc(calc(var(--vh, 1vh) * 100)-3.5rem-3.5rem)]"
          className="p-4 sm:p-6"
          onScroll={(e) => {
            const target = e.target as HTMLDivElement;
            handleScroll(target);
          }}
        >
          {isLoadingMore && currentChat?.chat_id === chat_id && (
            <Box sx={{ display: "flex", justifyContent: "center", mb: 2 }}>
              <CircularProgress
                size={20}
                sx={{ color: "rgba(255,255,255,0.7)" }}
              />
            </Box>
          )}

          {/* Message Bubble */}
          {/* <Typography sx={{ fontSize: 12, color: "rgba(255,255,255,0.5)" }}>
            {dayjs(chatDetail[0]?.create_time ?? 0 * 1000).format(
              "YYYY-MM-DD HH:mm"
            )}
          </Typography> */}
          {chatDetail.map((chat, index) => (
            <Box
              key={`${chat.id}-${index}`}
              sx={{
                alignSelf: chat.is_from_bot ? "flex-start" : "flex-end",
                maxWidth: "70%",
                borderRadius: 2,
                position: "relative",
                "&:hover .play-button": {
                  opacity: 1,
                },
                ...(chat.is_from_bot && !chat.loading && !chat.is_need_subscribe
                  ? {
                      bgcolor: "rgba(255,255,255,0.9)",
                      color: "#000",
                    }
                  : {
                      bgcolor: "rgba(255,255,255,0.1)",
                      color: "#fff",
                    }),
              }}
              className="sm:p-3 p-2"
            >
              {chat.is_need_subscribe ? (
                <SubscriptionCard />
              ) : chat.loading ? (
                <ThreeDotsLoading />
              ) : (
                <div className="flex items-center">
                  {chat.type === "image" ? (
                    <img
                      src={chat.content}
                      alt="AI generated chat pic"
                      width={192}
                      height={280}
                    />
                  ) : (
                    <Typography sx={{ textAlign: "left", pr: 3 }}>
                      {chat.content}
                    </Typography>
                  )}
                  {chat.is_from_bot && chat.type !== "image" && (
                    <IconButton
                      className="play-button"
                      size="small"
                      sx={{
                        position: "absolute",
                        right: -28,
                        // bottom: "30%",
                        width: 24,
                        height: 24,
                        opacity:
                          playingMessageId === chat.id ||
                          loadingMessageId === chat.id
                            ? 1
                            : 0,
                        transition: "opacity 0.2s",
                        color:
                          playingMessageId === chat.id
                            ? "#9170f0"
                            : "rgba(255,255,255,0.7)",
                        border: "1px solid",
                        borderColor:
                          playingMessageId === chat.id
                            ? "#9170f0"
                            : "rgba(255,255,255,0.3)",
                        padding: "4px",
                      }}
                      onClick={() => {
                        playSingleMessageVoice(
                          chat.content || "",
                          chat.id || "",
                          chat.type === "welcome"
                        );
                      }}
                    >
                      {loadingMessageId === chat.id ? (
                        <CircularProgress size={14} color="inherit" />
                      ) : playingMessageId === chat.id && isPlaying ? (
                        <PauseIcon sx={{ fontSize: 14 }} />
                      ) : (
                        <PlayArrowIcon sx={{ fontSize: 14 }} />
                      )}
                    </IconButton>
                  )}
                </div>
              )}
            </Box>
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {/* Input Area */}
        <Box
          sx={{
            display: "flex",
            gap: 2,
          }}
          className="sm:h-16 h-14 p-2"
        >
          <Tooltip
            title={
              <div className="p-2 text-white bg-black rounded-lg">
                <div className="text-sm">Ask for photos</div>
                <div className="text-gray-d">2 Credits per photo</div>
              </div>
            }
            componentsProps={{
              tooltip: {
                sx: {
                  padding: 0,
                  backgroundColor: "transparent",
                },
              },
            }}
          >
            <Box
              sx={{
                color: "white",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                borderRadius: 20,
                cursor: "pointer",
              }}
              className="sm:h-12 sm:w-32 w-10"
              onClick={() => {
                setPhotoOf(true);
              }}
            >
              <ImageIcon sx={{ fontSize: 24 }} />
              <span className="text-sm ml-1 sm:block hidden">Ask for</span>
            </Box>
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              flex: 1,
              bgcolor: "rgba(255,255,255,0.1)",
              borderRadius: 999,
            }}
            className="sm:p-2"
          >
            <InputBase
              sx={{
                flex: 1,
                color: "white",
                "& .MuiInputBase-input": {
                  p: 0,
                },
              }}
              className="pl-4"
              onChange={(e) => {
                if (e.target.value.startsWith("Photo of ")) {
                  setMsg(e.target.value.slice(9));
                  setPhotoOf(true);
                } else {
                  setMsg(e.target.value);
                }
              }}
              value={msg}
              placeholder="Type a message"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleSendMessage();
                }
              }}
              startAdornment={
                photoOf && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                      backgroundColor: "rgba(145, 112, 240, 0.2)",
                      borderRadius: "50px",
                      mr: 1,
                    }}
                    className="sm:py-2 sm:px-3 py-1 px-1 sm:min-w-[120px] min-w-[82px]"
                  >
                    <Typography
                      sx={{
                        color: "#9170f0",
                        fontSize: "14px",
                        flex: 1,
                      }}
                    >
                      Photo of
                    </Typography>
                    <IconButton
                      size="small"
                      sx={{
                        padding: 0,
                        color: "#9170f0",
                        "&:hover": { backgroundColor: "transparent" },
                      }}
                      onClick={() => setPhotoOf(false)}
                    >
                      <CloseIcon sx={{ fontSize: 16 }} />
                    </IconButton>
                  </Box>
                )
              }
              endAdornment={
                <IconButton
                  sx={{ color: "white" }}
                  onClick={() => {
                    handleSendMessage();
                  }}
                >
                  <SendIcon
                    className={`${
                      msg && canSendMessage && "text-primary-color"
                    }`}
                  />
                </IconButton>
              }
            />
          </Box>
        </Box>
      </Box>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        sx={{
          "& .MuiDrawer-paper": {
            maxWidth: "280px",
            bgcolor: "#1b1c2c",
            border: "none",
            color: "white",
          },
        }}
      >
        <ChatHistory className="flex" onClose={() => setShowDrawer(false)} />
      </Drawer>

      {/* Right Sidebar - Profile */}
      <Box
        sx={{
          width: showRightPanel ? 268 : 0,
          borderLeft: "1px solid rgba(255,255,255,0.1)",
          overflowY: "auto",
          maxHeight: "calc(100vh - 60px)",
          overflowX: "hidden",
          transition: "width 0.3s ease",
          opacity: showRightPanel ? 1 : 0,
        }}
        className="xl:flex hidden"
      >
        <Box
          sx={{
            textAlign: "center",
            width: 268,
            visibility: showRightPanel ? "visible" : "hidden",
          }}
        >
          <ImageCarousel
            images={currentChat?.photo_array || []}
            loading={pic_generate_loading}
          />
          <Typography variant="h6" sx={{ pt: 2, px: 2, textAlign: "left" }}>
            {currentChat?.title}
          </Typography>
          <Typography
            sx={{
              mb: 2,
              textAlign: "left",
              color: "rgba(255,255,255,0.6)",
              fontSize: 14,
              px: 2,
              width: 268,
            }}
          >
            {currentChat?.description}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 1,
              pb: 6,
              px: 2,
              width: 268,
            }}
          >
            {currentChat?.character_tags?.hobbies
              .concat(currentChat?.character_tags?.personality)
              .map((tag) => (
                <Chip
                  key={tag}
                  label={tag}
                  sx={{
                    bgcolor: "rgba(255,255,255,0.1)",
                    color: "white",
                  }}
                />
              ))}
          </Box>
        </Box>
      </Box>

      <AutoPlayGuide open={open} setOpen={setOpen} />
      {openSubscribeModal && (
        <SubscribeModal
          open={openSubscribeModal}
          setOpen={setOpenSubscribeModal}
        />
      )}
    </Box>
  );
}
