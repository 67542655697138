import { FaceModel } from "../../model/create/FaceModel";
import React from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";

interface IProps {
  faceModel: FaceModel;
  index: number;
}
export function FaceModelItem(props: IProps) {
  const { faceModel, index } = props;
  const { createEntity, setModel, resetStatus } = useFaceEditStores(
    (state) => ({
      createEntity: state.createEntity,
      setModel: state.setModel,
      resetStatus: state.resetStatus,
    }),
  );
  const selected = index == createEntity.faceModel.index;

  return (
    <div
      className="w-[69px] h-[69px] scroll-snap-align-start lg:mt-[15px] mr-[5px]  "
      onClick={() => setModel(faceModel)}
    >
      <div className="h-[1px] text-transparent "></div>
      <img
        className={`border-${
          selected ? "primary-color" : "gray"
        } border-[2px] rounded-[35px]  w-full h-full scroll-snap-align-start object-cover`}
        src={faceModel.resize_url}
      ></img>
    </div>
  );
}
