import { create } from "zustand";
import { apiService, baseURL, STORAGE_TOKEN } from "../service/Api";
import {
  AddSoulmateDialogDataType,
  BotTemplate,
  Chat,
  CreateCharacterParams,
  CreateMessageRequest,
  CreateRequest,
  GetSoulmatesRequest,
  Message,
  VoiceIdListVoice,
} from "../api/create/CreateApi";
import toast from "react-hot-toast";
import {
  APP_ID_DELORIS,
  AUTO_PLAY_KEY,
  NEED_SUB_TEXT,
  PROJECT_STATUS,
} from "../constant";
import { getReaderText } from "../util/utils";

type State = {
  soulmateList: BotTemplate[];
  voiceIdList: VoiceIdListVoice[];
  addSoulmateDialogData: AddSoulmateDialogDataType;
  characterTimerId: NodeJS.Timeout | null;
  chat_id: string;
  createProcessLoading: boolean;
  createProcessEnd: boolean;
  historyChatList: Chat[];
  chatDetail: Partial<Message>[];
  currentChat: Partial<
    Omit<BotTemplate, "photo_array"> & { photo_array: string[] }
  > | null;
  canSendMessage: boolean;
  template_id: string | null;
  currentAudio: HTMLAudioElement | null;
  playingMessageId: string | null;
  loadingMessageId: string | null;
  isPlaying: boolean;
  playHistoryInfo: {
    id: string;
    blobUrl: string;
  }[];
  chat_timerId: NodeJS.Timeout | null;
  face_model_id: string;
  pic_generate_loading: boolean;
  isLoadingMore: boolean;
  isCanScroll: boolean;
  isStopLoadMore: boolean;
  offset: number;
};

type Action = {
  getSoulmatesList: (req: GetSoulmatesRequest) => Promise<void>;
  createPicID: (params: CreateRequest) => Promise<string>;
  createChatPic: (params: CreateRequest) => void;
  getPicByID: (project_id: string) => void;
  setAddSoulmateDialogData: (data: Partial<AddSoulmateDialogDataType>) => void;
  customCharacter: (params: CreateCharacterParams) => void;
  getInitData: () => void;
  getHistoryChatList: () => void;
  getChatDetail: (chat_id: string, offset?: number) => void;
  sendMessage: (
    req: CreateMessageRequest,
    isAskPic?: boolean,
    picParams?: CreateRequest
  ) => void;
  getGreetings: (chat_id: string) => void;
  deleteCharacter: (chat_id: string) => boolean;
  deleteChatHistory: (chat_id: string) => void;
  setHistoryChatList: (list: Chat[]) => void;
  playVoice: ({
    content,
    voice_id,
    isAudioPlaying,
    currentIsPlayingId,
    is_welcome_msg,
  }: {
    content: string;
    voice_id?: string;
    isAudioPlaying?: boolean;
    currentIsPlayingId?: string;
    is_welcome_msg?: boolean;
  }) => Promise<HTMLAudioElement>;
  playSingleMessageVoice: (
    content: string,
    chat_id: string,
    is_welcome_msg?: boolean
  ) => void;
  initAudio: () => void;
  cleanUpOpt: () => void;
  handleLoadMore: (chat_id: string) => void;
  clearStore: () => void;
};

const initialState: State = {
  soulmateList: [], // 灵魂伴侣列表
  voiceIdList: [], // 语音ID列表
  characterTimerId: null, // 定时器id
  chat_id: "", // 聊天窗口ID
  createProcessLoading: false, // 创建角色进度loading
  createProcessEnd: false, // 创建角色进度结束
  addSoulmateDialogData: {
    // 创建灵魂伴侣的对话框数据
    selectedOtherTags: [], // 选中的其他标签
    personalityAndHobbiesTags: [], // 选中的性格和爱好标签
    create_four_pics_UrlList: [], // 创建四张图片的url列表
    create_four_pics_timerId: null, // 创建四张图片的定时器id
    soulmate_age: 18, // 创建的灵魂伴侣的年龄
    soulmate_name: "", // 创建的灵魂伴侣的名字
    selectedFace: null, // 选中的人脸图片信息
    voice_id: "", // 选中的语音ID
    group_id: "", // 选中的群组ID
  },
  historyChatList: [], // 历史人设记录列表
  chatDetail: [], // 聊天详情
  currentChat: null, // 当前聊天
  canSendMessage: true, // 是否可以发送消息
  template_id: null, // 当前模板id
  currentAudio: null,
  playingMessageId: null,
  loadingMessageId: null,
  playHistoryInfo: [],
  isPlaying: false,
  chat_timerId: null,
  face_model_id: "",
  pic_generate_loading: false,
  isLoadingMore: false,
  isCanScroll: true,
  isStopLoadMore: false,
  offset: 0,
};

export const useSoulChatStore = create<State & Action>()((set, get) => ({
  ...JSON.parse(JSON.stringify(initialState)),
  setAddSoulmateDialogData: (data: Partial<AddSoulmateDialogDataType>) => {
    set({
      addSoulmateDialogData: {
        ...get().addSoulmateDialogData,
        ...data,
      },
    });
  },
  handleLoadMore: (chat_id: string) => {
    const { chatDetail, getChatDetail, isLoadingMore, isStopLoadMore, offset } =
      get();
    if (chatDetail.length >= 20 && !isLoadingMore && !isStopLoadMore) {
      set({
        isLoadingMore: true,
        isCanScroll: false,
        offset: offset + 20,
      });
      getChatDetail(chat_id, offset + 20);
    }
  },
  async getSoulmatesList(req: GetSoulmatesRequest) {
    const resp = await apiService.GetSoulmatesList(req);
    if (resp) {
      set((state) => ({
        soulmateList: resp?.data?.bot_templates || [],
      }));
    }
  },
  createPicID: (params: CreateRequest) => {
    return new Promise((resolve, reject) => {
      apiService.CreateTask(params).then((response) => {
        if (response.code === 200) {
          if (response.data) {
            resolve(response.data.id);
            set({
              face_model_id: response.data?.project_config?.face_model_id || "",
            });
          }
        } else if (response.code === 200005) {
          toast.error(`${response.message}`);
        } else {
          toast.error(`${response.message}`);
        }
      });
    });
  },
  getPicByID: async (project_id: string) => {
    const addSoulmateDialogData = get().addSoulmateDialogData;
    const timer_id = addSoulmateDialogData.create_four_pics_timerId;
    if (timer_id) clearInterval(timer_id);

    const timerId = setInterval(async () => {
      const response = await apiService.QueryTask({ project_id });
      if (response?.data?.project_status && response?.code === 200) {
        if (response.data.project_status[1] === PROJECT_STATUS.GENERATED) {
          const photo_array = response.data.project_segments.map(
            (item) => item.image_url
          ) as string[];
          const currentChat = get().currentChat;
          set({
            addSoulmateDialogData: {
              ...addSoulmateDialogData,
              create_four_pics_UrlList: photo_array,
              create_four_pics_timerId: null,
            },
            currentChat: currentChat
              ? {
                  ...currentChat,
                  photo_array: photo_array || [],
                }
              : {
                  photo_array: photo_array || [],
                },
            pic_generate_loading: false,
          });
          // 清除定时器
          clearInterval(timerId);
          // 图片生成后调用修改模板api
          const template_id = get().template_id;
          if (template_id) {
            apiService.editCharacter({
              template_id,
              photo_array: JSON.stringify(photo_array),
            });
          }
          return;
        }
      } else {
        toast.error(`Error: Fetch TaskDetail Info Error`);
      }
    }, 4000);
    set({
      addSoulmateDialogData: {
        ...addSoulmateDialogData,
        create_four_pics_timerId: timerId,
      },
      pic_generate_loading: true,
    });
  },
  getInitData: async () => {
    const resp = await Promise.all([
      apiService.getVoiceIdList(),
      apiService.getGroups(),
    ]);
    set({
      voiceIdList: resp[0]?.data?.voices || [],
      addSoulmateDialogData: {
        ...get().addSoulmateDialogData,
        voice_id: resp[0]?.data?.voices?.[0]?.id || "",
        group_id:
          resp[1]?.data?.find((item) => item.title === APP_ID_DELORIS)?.id ||
          "",
      },
    });
  },
  customCharacter: async (params: CreateCharacterParams) => {
    set({
      createProcessLoading: true,
    });
    // 1. 调用创建角色api获取message_id和id
    const resp = await apiService.createCharacter(params);
    if (resp.code === 200 && resp.data.message_id) {
      set({
        template_id: resp.data.id,
      });
      // 2. 调用创建聊天窗口api获取chat_id
      apiService.createChat({ bot_template: resp.data.id }).then((res) => {
        if (res.code === 200 && res.data.id) {
          set({
            chat_id: res.data.id,
          });
        }
      });
      // 2并行. 调用get_seo_article api获取人设描述和人设prompt
      const characterTimerId = get().characterTimerId;
      if (characterTimerId) clearInterval(characterTimerId);

      const timerId = setInterval(async () => {
        const resp2 = await apiService.queryCharacterDescriptionAndPrompt({
          message_id: resp.data.message_id,
        });
        if (resp2.data.content) {
          clearInterval(timerId);
          const cont = resp2.data.content.split("\n\n");
          set({
            characterTimerId: null,
          });
          // 3. 获取到人设描述和人设prompt后，调用编辑角色api，传入description、sys_prompt、template_id
          await apiService.editCharacter({
            description: cont[0],
            sys_prompt: cont[1],
            template_id: resp.data.id,
          });
          set({
            createProcessLoading: false,
            createProcessEnd: true,
          });
          get().getHistoryChatList();
        }
      }, 2000);

      set({
        characterTimerId: timerId,
      });
    }
  },
  getHistoryChatList: async () => {
    const resp = await apiService.queryHistoryChatList();
    set({
      historyChatList: resp?.data?.chats || [],
    });
  },
  getChatDetail: async (chat_id: string, offset?: number) => {
    const resp = await apiService.queryChatDetail(chat_id, offset);
    const bot_template = resp?.data?.bot_template;
    let photo_array = [];
    try {
      photo_array = bot_template?.photo_array
        ? JSON.parse(bot_template.photo_array)
        : [];
    } catch {}
    let messages = [];
    const resp_messages = resp?.data?.messages.messages.reverse();
    if (offset) {
      messages = resp_messages.concat(get().chatDetail as Message[]) || [];
    } else {
      messages = resp_messages || [];
      set({
        isCanScroll: true,
      });
    }
    set({
      chatDetail: messages,
      currentChat: {
        ...bot_template,
        photo_array,
        chat_id: resp?.data?.chat_id,
      },
      isLoadingMore: false,
      isStopLoadMore: resp_messages.length < 20,
    });
  },
  sendMessage: async (
    req: CreateMessageRequest,
    isAskPic: boolean = false,
    picParams: CreateRequest
  ) => {
    const currentAudio = get().currentAudio;
    if (currentAudio) {
      currentAudio.muted = false;
    }
    const chatDetail = get().chatDetail;
    const newChatDetail = [
      ...chatDetail,
      {
        id: Date.now().toString() + Math.random(),
        content: req.content,
        is_from_bot: false,
      },
      {
        id: Date.now().toString() + Math.random(),
        content: "",
        is_from_bot: true,
        loading: true,
      },
    ];
    set({
      chatDetail: newChatDetail,
      canSendMessage: false,
      isCanScroll: true,
    });
    if (isAskPic) {
      get().createChatPic(picParams);
      return;
    }
    try {
      const response = await fetch(`${baseURL}/v1/chats/message`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN)}`,
        },
        body: JSON.stringify({ ...req }),
      });

      if (!response.ok || !response.body) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();
      let accumulatedMessage = "";
      let id;
      while (reader !== null) {
        const { done, value } = await reader.read();
        if (done) {
          get().playVoice({
            content: accumulatedMessage || "",
            isAudioPlaying: localStorage.getItem(AUTO_PLAY_KEY) === "true",
            currentIsPlayingId: id,
          });
          break;
        }
        if (value) {
          const chatDetail = get().chatDetail;
          chatDetail.pop();
          id = Date.now().toString() + Math.random();
          accumulatedMessage += getReaderText(value);
          if (accumulatedMessage.includes(NEED_SUB_TEXT)) {
            set({
              chatDetail: [
                ...chatDetail,
                {
                  id,
                  content: "",
                  is_from_bot: true,
                  loading: false,
                  is_need_subscribe: true,
                },
              ],
              canSendMessage: true,
            });
            return;
          }
          set({
            chatDetail: [
              ...chatDetail,
              {
                id,
                content: accumulatedMessage,
                is_from_bot: true,
              },
            ],
            canSendMessage: true,
          });
        }
      }
    } catch (error) {
      set({
        canSendMessage: true,
      });
      console.error("Error:", error);
    }
  },
  getGreetings: async (chat_id: string) => {
    const currentAudio = get().currentAudio;
    if (currentAudio) {
      currentAudio.muted = false;
    }
    try {
      set({
        chatDetail: [
          {
            id: Date.now().toString(),
            content: "",
            is_from_bot: true,
            loading: true,
          },
        ],
        canSendMessage: false,
      });
      const response = await fetch(
        `${baseURL}/v1/chats/welcome_message?chat_id=${chat_id}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN)}`,
          },
        }
      );

      if (!response.ok || !response.body) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body
        ?.pipeThrough(new TextDecoderStream())
        .getReader();
      let greeting_msg = "";
      let id;
      while (reader !== null) {
        const { done, value } = await reader.read();
        if (done) {
          get().playVoice({
            content: greeting_msg,
            isAudioPlaying: localStorage.getItem(AUTO_PLAY_KEY) === "true",
            currentIsPlayingId: id,
          });

          break;
        }
        if (value) {
          id = Date.now().toString() + Math.random();
          greeting_msg += getReaderText(value);
          if (greeting_msg) {
            set({
              chatDetail: [
                {
                  id,
                  content: greeting_msg,
                  is_from_bot: true,
                },
              ],
              canSendMessage: true,
            });
          }
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  },
  // 初始化音频
  initAudio: () => {
    const audio = new Audio();
    audio.muted = true;
    audio.addEventListener("play", () => {
      set({ isPlaying: true, loadingMessageId: null });
    });
    audio.addEventListener("ended", () => {
      set({ playingMessageId: null, isPlaying: false });
    });
    audio.addEventListener("error", (e) => {
      const audio = e.target as HTMLAudioElement;
      console.error("Audio error:", {
        error: audio.error,
        errorCode: audio.error?.code,
        errorMessage: audio.error?.message,
        currentSrc: audio.currentSrc,
        readyState: audio.readyState,
      });

      switch (audio.error?.code) {
        case MediaError.MEDIA_ERR_ABORTED: // 1
          console.error("Audio loading aborted");
          break;
        case MediaError.MEDIA_ERR_NETWORK: // 2
          console.error("Network error while loading audio");
          break;
        case MediaError.MEDIA_ERR_DECODE: // 3
          console.error("Audio decoding failed");
          break;
        case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED: // 4
          console.error("Audio format not supported");
          break;
      }

      set({
        loadingMessageId: null,
        playingMessageId: null,
        isPlaying: false,
      });
    });
    set({ currentAudio: audio });
  },
  // 语音播放
  playVoice: async ({
    content,
    voice_id,
    isAudioPlaying = false,
    currentIsPlayingId,
    is_welcome_msg,
  }: {
    content: string;
    voice_id?: string;
    isAudioPlaying?: boolean;
    currentIsPlayingId?: string;
    is_welcome_msg?: boolean;
  }) => {
    const voiceId = voice_id || get().currentChat?.voice_id || "";
    if (!isAudioPlaying || !voiceId || !content) return;

    set({
      loadingMessageId: currentIsPlayingId,
      playingMessageId: currentIsPlayingId || "",
    });

    const currentAudio = get().currentAudio;

    if (currentAudio) {
      currentAudio.pause();
      // 先从历史记录中获取blobUrl
      let srcUrl =
        get().playHistoryInfo.find((item) => item.id === currentIsPlayingId)
          ?.blobUrl || "";
      if (srcUrl) {
        currentAudio.src = srcUrl;
        try {
          currentAudio.play();
        } catch (error) {
          console.error("Error:", error);
        }
        return;
      }

      const blobUrl = await apiService.getBlobSrc({
        content,
        voice_id: voiceId,
        is_welcome_msg: is_welcome_msg || false,
        template_id: get().currentChat?.id || "",
      });
      currentAudio.src = blobUrl; // 设置音频的源为 Blob URL
      set({
        playHistoryInfo: [
          ...get().playHistoryInfo,
          {
            id: currentIsPlayingId || "",
            blobUrl,
          },
        ],
      });
      try {
        currentAudio.play();
      } catch (error) {
        console.error("Error:", error);
      }
    }
  },
  // 点击单条播放
  playSingleMessageVoice: async (
    content: string,
    chat_id: string,
    is_welcome_msg?: boolean
  ) => {
    const { playingMessageId, currentAudio } = get();
    if (currentAudio) {
      currentAudio.muted = false;
    }

    // 如果点击的是当前正在播放的消息
    if (playingMessageId === chat_id && currentAudio) {
      if (!currentAudio?.paused) {
        // 暂停时记住当前播放位置
        currentAudio?.pause();
        set({ isPlaying: false });
      } else {
        // 从暂停的位置继续播放
        try {
          currentAudio.play();
        } catch (error) {
          console.error("Error:", error);
        }
        set({ isPlaying: true });
      }
      return;
    }

    get().playVoice({
      content,
      isAudioPlaying: true,
      currentIsPlayingId: chat_id,
      is_welcome_msg,
    });
  },
  deleteChatHistory: async (chat_id: string) => {
    const resp = await apiService.deleteChatHistory(chat_id);
    if (resp.code === 200) {
      get().getChatDetail(chat_id);
      const currentAudio = get().currentAudio;
      if (currentAudio) {
        currentAudio.pause();
        currentAudio.muted = true;
      }
    }
  },
  setHistoryChatList: (list: Chat[]) => {
    set({
      historyChatList: list,
    });
  },
  deleteCharacter: async (chat_id: string) => {
    const resp = await apiService.deleteCharacter({ chat_id });
    if (resp.code === 200) {
      get().getHistoryChatList();
      return true;
    }
    return false;
  },
  cleanUpOpt: () => {
    const currentAudio = get().currentAudio;
    if (currentAudio) {
      currentAudio.pause();
    }
    set({
      face_model_id: "",
      createProcessEnd: false,
      offset: 0,
      isLoadingMore: false,
      isStopLoadMore: false,
      isCanScroll: true,
      loadingMessageId: null,
      playingMessageId: null,
      isPlaying: false,
    });
  },
  // 聊天窗口生成图片
  createChatPic: async (params: CreateRequest) => {
    try {
      const pic_id = await get().createPicID(params);
      const chat_timerId = get().chat_timerId;
      if (chat_timerId) clearInterval(chat_timerId);

      const timerId = setInterval(async () => {
        const response = await apiService.QueryTask({ project_id: pic_id });
        if (response?.data?.project_status && response?.code === 200) {
          if (response.data.project_status[1] === PROJECT_STATUS.GENERATED) {
            const photos = response.data.project_segments.map(
              (item) => item.image_url
            ) as string[];
            const chatDetail = get().chatDetail;
            chatDetail.pop();
            // // 调用接口保存本次生成的图片
            // apiService.saveChatHistory({
            //   content: photos[0],
            //   chat: params?.project_config?.chat_id || "",
            //   is_from_bot: true,
            // })
            set({
              chatDetail: [
                ...chatDetail,
                {
                  id: Date.now().toString() + Math.random(),
                  content: photos[0],
                  is_from_bot: true,
                  type: "image",
                },
              ],
              canSendMessage: true,
            });
            // 清除定时器
            clearInterval(timerId);
          }
        } else {
          set({
            canSendMessage: true,
          });
          toast.error(`Error: Fetch TaskDetail Info Error`);
        }
      }, 2000);

      set({
        chat_timerId: timerId,
      });
    } catch (error) {
      set({
        canSendMessage: true,
      });
    }
  },
  clearStore: () => {
    const currentAudio = get().currentAudio;
    if (currentAudio) {
      currentAudio.pause();
    }
    set(JSON.parse(JSON.stringify(initialState)));
  },
}));

export const clearSoulChatStore = useSoulChatStore.getState().clearStore;
