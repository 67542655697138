import React from "react";
import { ProjectStatusEnum } from "../../api/task/TaskApi";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { useTranslation } from "react-i18next";

export function VideoFailed() {
  const { t } = useTranslation();
  const { project } = useFaceEditStores((state) => ({
    project: state.project,
  }));
  const errorText = () => {
    const status_code = (project && project.project_status[0]) || 0;
    if (status_code == ProjectStatusEnum.SwapGenerateFailed) {
      return t("analysis_failed");
    } else if (status_code == ProjectStatusEnum.FileNotSupport) {
      return t("upload_file_type_not_support");
    } else if (status_code == ProjectStatusEnum.FileOverSize) {
      return t("upload_file_is_oversize");
    } else if (status_code == ProjectStatusEnum.VideoNotContainFaces) {
      return t("does_not_contain_any_faces");
    }
    return t("analysis_failed");
  };

  return (
    <div className="flex-row justify-start items-center w-full h-full">
      <p className="text-red-800 text-[24px] mt-[24px]">
        {t("analysis_failed")}
      </p>
      <p className="text-red-800 text-[18px] mt-[18px]">{errorText()}</p>
    </div>
  );
}
