import { logEvent } from "firebase/analytics";
import { SOURCE_ID_KEY, analytics } from "../App";

export const getReaderText = (str: string) => {
  let matchStr = '';
  try {
    const result = str.match(/data:\s*({.*?})\s*\n/g);
    result?.forEach((_: string) => {
      const matchStrItem = _.match(/data:\s*({.*?})\s*\n/)?.[1];
      const data = matchStrItem ? JSON.parse(matchStrItem) : '';
      matchStr += data?.chunk || '';
    });
  } catch (e) {
    console.log(e);
  }
  return matchStr;
};

export const firebaseLogEvent = (event: string) => {
  const sourceId = localStorage.getItem(SOURCE_ID_KEY);
  logEvent(analytics, event, {
    page_path: event,
    source_id: sourceId,
  });
};
