import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import React from "react";
import { useTranslation } from "react-i18next";

export default function ItemNSFW() {
  const { t } = useTranslation();
  return (
    <div className="relative w-full h-full rounded-[20px] text-white h-full bg-red flex flex-row justify-center items-center bg-gray">
      <img
        src={require("../../../src/assets/login_bg.webp")}
        className="w-full h-full blur-sm rounded-[20px] p-1"
      ></img>
      <div className="text-[18px] text-transparent text-white absolute font-bold inset-0 flex flex-col items-center justify-center ">
        <FontAwesomeIcon
          className="ml-[10px]"
          size={"2x"}
          color="white"
          icon={faCircleExclamation}
        />
        <div className="text-white text-[17px] mt-[10px]">
          {t("nsfw_content")}
        </div>
      </div>
    </div>
  );
}
