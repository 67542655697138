import { Box, Fab, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const AddButton = ({
  onClick,
  isPro = false,
}: {
  onClick: (e: React.MouseEvent) => void;
  isPro?: boolean;
}) => {
  return (
    <Box
      sx={{
        position: "relative",
        width: {
          sm: 69,
          xs: 60,
        },
        height: {
          sm: 69,
          xs: 60,
        },
      }}
      onClick={onClick}
    >
      <Fab
        sx={{
          width: "100%",
          height: "100%",
          bgcolor: "grey.700",
          "&:hover": {
            bgcolor: "grey.600",
          },
        }}
      >
        <AddIcon sx={{ color: "white", fontSize: 28 }} />
      </Fab>
      {isPro && (
        <Typography
          sx={{
            position: "absolute",
            top: -2,
            right: -10,
            bgcolor: "error.main",
            color: "white",
            fontSize: 12,
            fontWeight: "bold",
            px: 1,
            py: 0.2,
            borderRadius: 6,
            minWidth: 35,
            textAlign: "center",
            zIndex: 1051,
          }}
        >
          Pro
        </Typography>
      )}
    </Box>
  );
};

export default AddButton;
