import { Box, keyframes } from "@mui/material";
import { styled } from "@mui/material/styles";

const bounceAnimation = keyframes`
  0%, 80%, 100% {
    transform: scale(0);
    opacity: 0.3;
  }
  40% {
    transform: scale(1);
    opacity: 1;
  }
`;

const Dot = styled(Box)(({ theme }) => ({
  width: 6,
  height: 6,
  backgroundColor: "currentColor",
  borderRadius: "50%",
  display: "inline-block",
  margin: "0 2px",
  animation: `${bounceAnimation} 1.4s infinite ease-in-out both`,
}));

interface ThreeDotsLoadingProps {
  color?: string;
  size?: number;
}

const ThreeDotsLoading = ({
  color = "#fff",
  size = 6,
}: ThreeDotsLoadingProps) => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "2px",
        color: color,
      }}
    >
      <Dot sx={{ animationDelay: "-0.32s", width: size, height: size }} />
      <Dot sx={{ animationDelay: "-0.16s", width: size, height: size }} />
      <Dot sx={{ width: size, height: size }} />
    </Box>
  );
};

export default ThreeDotsLoading;
