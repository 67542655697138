import { create } from "zustand";
import { CreateEntity, TaskType } from "../model/create/CreateEntity";
import { PromptTagEntity } from "../model/create/PromptTagEntity";
import { ModelEntity } from "../model/create/ModelEntity";
import { ModelTagEntity } from "../model/create/ModelTagEntity";
import { StylesEntity } from "../model/create/StylesEntity";
import { ImageSizeEntity } from "../model/create/ImageSizeEntity";
import { ConfigEntity } from "../model/config/ConfigEntity";
import { ConfigData } from "../model/config/ConfigData";
import { apiService, isValidResponse, ResponseWrap } from "../service/Api";
import { CreateResponse } from "../api/create/CreateApi";
import toast, { Toaster } from "react-hot-toast";
import { CreateTabEntity } from "../model/config/CreateTabEntity";
import { HistoryTag } from "../model/create/HistoryTag";
import { FaceModel, FaceModelStatus } from "../model/create/FaceModel";
import { ProjectEntity } from "../api/task/TaskApi";
import { logEvent } from "firebase/analytics";
import { analytics } from "../App";
import { ACTION_FACE_EDIT_MODEL_UPLAOD } from "../util/Event";

export const STORAGE_FACE_MODEL_LIST = "FACE_MODEL_LIST";

const KEY_HISTORY_TAG = "KEY_HISTORY_TAG";
const KEY_ONBOARDING_001 = "KEY_ONBOARDING_001";
const MAX_HISTORY_SIZE = 10;
type Actions = {
  addPromptTag: (tag: PromptTagEntity) => void;
  clearAllPromptTag: () => void;
  updatePrompt: (prompt: string) => void;
  setStyle: (style: StylesEntity) => void;
  setModel: (modeEntity: FaceModel) => void;
  addModelTag: (tag: ModelTagEntity) => void;
  setSizeOfImage: (size: ImageSizeEntity) => void;
  setBatch: (batch: number) => void;
  createTask: () => void;
  resetStatus: () => void;
  dismissErrDialog: () => void;
  queryMaterial: (scene?: string) => void;
  addHistoryTag: (content: string) => void;
  getHistoryTags: () => HistoryTag[];
  uploadFace: (file: File) => void;
  fetchFaces: () => void;
  fetchFacesFromLocal: () => void;

  queryFaceModeStatus: () => void;
  queryAnalysisResult: () => void;

  cancelFaceModel: () => void;
  addFaceModelFiled: () => void;
  showFaceModelInfo: () => void;

  hideFaceModelInfo: () => void;

  recoverCreateEntity: (reCreateEntity: ProjectEntity) => void;
  initOnboarding: () => void;

  hideOnboardingDialog: () => void;

  showHint(msg: string): void;
};

type State = {
  configEntity: ConfigEntity;
  gotoTask: boolean;
  gotoSubscribe: boolean;
  creatingTask: boolean;
  showErrDialog: boolean;
  errMessage: string;
  errCode: number;
  createEntity: CreateEntity;
  tabs: CreateTabEntity[];
  historyTags: HistoryTag[];
  uploadFile: File | null;
  showUploadingDialog: boolean;
  faces: FaceModel[];
  uploadingFaceModel: FaceModel | null;

  uploadingDialogTitle: string;

  faceModelLoopQuery: boolean;
  showFaceModelInfoDialog: boolean;
  showOnboarding: boolean;
  showFaceMode: boolean;
  previewOriginVideo: boolean;
};

const initStatus: State = {
  configEntity: ConfigData,
  gotoTask: false,
  gotoSubscribe: false,
  creatingTask: false,
  tabs: [],
  showErrDialog: false,
  showUploadingDialog: false,
  errCode: 0,
  errMessage: "",
  createEntity: {
    project_id: "",
    bears: 0,
    prompt: "",
    promptTags: [],
    style: { index: 0, name: "", image: "", model_name: "" },
    model: {
      index: 0,
      name: "Real Girl",
      model_name: "edge-of-realism",
      image:
        "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/20240212-210356.jpg",
    },
    batchNumber: 1,
    faceModel: {
      id: "",
      detection_result: false,
      index: -1,
      resize_url:
        "https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/MnNIMybLzD/a63534ed-a901-4e8b-baeb-3e448597ccf6.jpeg",
      url: "https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/MnNIMybLzD/a63534ed-a901-4e8b-baeb-3e448597ccf6.jpeg",
    },
  },
  uploadingFaceModel: null,
  uploadingDialogTitle: "Uploading",
  historyTags: [],
  uploadFile: null,
  faceModelLoopQuery: false,
  faces: [],
  showFaceModelInfoDialog: false,
  showFaceMode: true,
  showOnboarding: false,
  previewOriginVideo: true,
};

export const useCreateStores = create<Actions & State>((set, get) => ({
  ...JSON.parse(JSON.stringify(initStatus)),
  addPromptTag: (tag: PromptTagEntity) => {
    get().createEntity.promptTags.push(tag);
    if (get().createEntity.prompt.length > 0) {
      get().createEntity.prompt += "," + tag.name;
    } else {
      get().createEntity.prompt += tag.name;
    }
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },
  updatePrompt: (prompt) => {
    get().createEntity.prompt = prompt;
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },
  clearAllPromptTag: () => {
    get().createEntity.prompt = "";
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },

  setStyle: (style: StylesEntity) => {
    get().createEntity.model = style;
    set((state) => ({
      createEntity: state.createEntity,
      showFaceMode:
        style && style.model_name === "edge-of-realism" ? true : false,
    }));
  },
  setModel: (modeEntity: FaceModel) => {
    get().createEntity.faceModel = modeEntity;
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },
  addModelTag: (tag: ModelTagEntity) => {},
  setSizeOfImage: (size: ImageSizeEntity) => {},
  setBatch: (batch: number) => {
    get().createEntity.batchNumber = batch;
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },
  initOnboarding: async () => {
    const showOnboarding = localStorage.getItem(KEY_ONBOARDING_001);
    set((state) => ({
      showOnboarding: showOnboarding == null ? true : false,
    }));
    localStorage.setItem(KEY_ONBOARDING_001, "shown");
  },
  hideOnboardingDialog: async () => {
    set((state) => ({
      showOnboarding: false,
    }));
  },
  queryMaterial: async (scene?: string) => {
    const materialReq = { scene };
    apiService.QueryMaterial(materialReq).then((response) => {
      if (response.code === 200) {
        set(() => ({
          tabs: response.data.tabs,
        }));
      }
    });
  },

  addHistoryTag: (content: string) => {
    const historyTags = get().historyTags;
    const index = historyTags.length;
    const addItem: HistoryTag = {
      index: index,
      content: content,
    };
    historyTags.push(addItem);
    set((state) => ({
      historyTags: historyTags,
    }));
    if (historyTags.length > MAX_HISTORY_SIZE) {
      historyTags.shift();
    }
    localStorage.setItem(KEY_HISTORY_TAG, JSON.stringify(historyTags));
  },

  getHistoryTags: () => {
    const storageHistory = localStorage.getItem(KEY_HISTORY_TAG);
    if (storageHistory) {
      const historyData: [] = JSON.parse(storageHistory);
      if (historyData && historyData.length > 0) {
        historyData.reverse();
        set((state) => ({
          historyTags: historyData,
        }));
      }
    }
  },
  recoverCreateEntity: (reCreateEntity: ProjectEntity) => {
    const createEntity = get().createEntity;
    if (reCreateEntity) {
      if (reCreateEntity.project_config) {
        //恢复prompt
        createEntity.prompt = reCreateEntity.project_novel_text;
        //恢复生图配置
        createEntity.model.model_name =
          reCreateEntity.project_config.sd_model_name;
        createEntity.model.name = reCreateEntity.project_config.lora_model_name;
        //匹配模型和选中题态
        if (reCreateEntity.project_config.sd_model_name) {
          get().configEntity.modelStyle.find((item, index) => {
            if (
              item.model_name === reCreateEntity.project_config.sd_model_name
            ) {
              createEntity.model.index = item.index;
            }
          });
        }
        set((state) => ({
          showFaceMode:
            reCreateEntity &&
            reCreateEntity.project_config.sd_model_name === "edge-of-realism"
              ? true
              : false,
        }));
        //如果是真人生成，匹配脸部模型列表和选中台
        createEntity.batchNumber = reCreateEntity.project_config.batch_number;
        if (
          reCreateEntity.project_config.face_model_id &&
          reCreateEntity.project_config.face_model_id !== ""
        ) {
          const faceModelId = reCreateEntity.project_config.face_model_id;
          const facesModelList = get().faces;
          facesModelList.find((item: FaceModel, index) => {
            if (item.id === faceModelId) {
              createEntity.faceModel.index = index;
              createEntity.faceModel = item;
              return true;
            }
          });
        }
      }
    }
  },

  createTask: async () => {
    const createEntity: CreateEntity = get().createEntity;
    set((state) => ({
      creatingTask: true,
    }));

    apiService
      .CreateTask({
        project_name: "123123",
        project_novel_text: createEntity.prompt,
        project_type: TaskType.image,
        project_config: {
          sd_model_name: createEntity.model.model_name,
          sd_image_ratio: "2:3",
          batch_number: createEntity.batchNumber,
          lora_model_name: createEntity.model.name,
          face_model_id:
            createEntity.faceModel && createEntity.faceModel.id !== ""
              ? createEntity.faceModel.id
              : null,
          voice_name: "",
          voice_speed: 1,
          voice_volume: 11,
          background_music_url: "",
        },
      })
      .then((response) => {
        console.log(`create :${response}`);
        if (response.code === 200) {
          if (response.data) {
            createEntity.project_id = response.data.id;
          }
          set((state) => ({
            creatingTask: false,
            gotoTask: true,
          }));
          get().addHistoryTag(createEntity.prompt);
        } else if (
          response.code === 200005 ||
          response.code === 600001 ||
          response.code === 200004
        ) {
          set((state) => ({
            creatingTask: false,
            createEntity: createEntity,
            errCode: response.code,
            errMessage: response.message,
            gotoSubscribe: true,
          }));
          toast.success(`${response.message}`);
        } else {
          toast.error(`${response.message}`);
          set((state) => ({
            creatingTask: false,
            createEntity: createEntity,
            errCode: response.code,
            errMessage: response.message,
          }));
        }
      });
  },
  dismissErrDialog: () => {
    set((state) => ({
      showErrDialog: false,
      errCode: 0,
      errMessage: "",
    }));
  },
  resetStatus: () => {
    set(JSON.parse(JSON.stringify(initStatus)));
  },
  uploadFace: (file: File) => {
    set((state) => ({
      showUploadingDialog: true,
      uploadingDialogTitle: "Uploading...",
    }));
    apiService
      .UploadFace({
        file: file,
      })
      .then((resp) => {
        if (resp.code == 200) {
          console.log(`upload success${resp.data}`);
          logEvent(analytics, ACTION_FACE_EDIT_MODEL_UPLAOD, {});
          const uploadingFaceModel = resp.data;
          if (
            uploadingFaceModel &&
            uploadingFaceModel.status &&
            uploadingFaceModel.status[0] === FaceModelStatus.uploaded
          ) {
            set((state) => ({
              uploadingFaceModel: resp.data,
              uploadingDialogTitle: "Analysis in progress...",
            }));
          } else {
            toast.error("Face Model Upload Failed");
            set((state) => ({
              showUploadingDialog: false,
            }));
          }
        } else {
          toast.error("Add Face Model Failed");
          console.log("upload error");
          set((state) => ({
            showUploadingDialog: false,
          }));
        }
      })
      .catch(() => {
        toast.error("Add Face Model Failed");
        console.log("upload error");
        set((state) => ({
          showUploadingDialog: false,
        }));
      });
  },
  async queryAnalysisResult() {
    let retryCount = 0;
    const faceModel = get().uploadingFaceModel;
    if (faceModel && faceModel.id) {
      set((state) => ({
        faceModelLoopQuery: true,
      }));
    }
    while (retryCount < 10) {
      const loopQuery = get().faceModelLoopQuery;
      const uploadingFaceModel = get().uploadingFaceModel;
      if (!loopQuery) {
        return;
      }
      if (faceModel && faceModel.id) {
        apiService
          .QueryFaces({ face_model_id: faceModel?.id })
          .then((response) => {
            const faceModel = response.data;
            if (response.data && faceModel.status && response.code === 200) {
              console.log(`facemodel status:${FaceModelStatus.face_detected}`);
              if (faceModel.status[0] == FaceModelStatus.face_detected) {
                //当前face model通过检测状态
                if (faceModel.detection_result) {
                  toast.success("Face Model Upload Success");
                  set((state) => ({
                    showUploadingDialog: false,
                    uploadingFaceModel: null,
                    faceModelLoopQuery: false,
                    uploadingDialogTitle: "Uploading...",
                  }));
                  get().fetchFaces();
                } else {
                  set((state) => ({
                    showUploadingDialog: false,
                    uploadingFaceModel: null,
                    faceModelLoopQuery: false,
                    uploadingDialogTitle: "Uploading...",
                  }));
                  toast.error("Face Detection Failed");
                  return;
                }
                return;
              }
            }
          })
          .catch((err) => {
            console.log(`upload error${err}`);
          });
      }
      await new Promise((r) => setTimeout(r, 5000)); //
    }
    get().addFaceModelFiled();
  },
  addFaceModelFiled: () => {
    set((state) => ({
      showUploadingDialog: false,
    }));
    toast.error("Add Face Model Failed");
  },
  fetchFaces: () => {
    apiService
      .GetFaces({})
      .then((response) => {
        if (response.code === 200) {
          response.data.map((item, index) => {
            item.index = index;
          });
          set((state) => ({
            faces: response.data,
          }));
          localStorage.setItem(
            STORAGE_FACE_MODEL_LIST,
            JSON.stringify(response.data),
          );
          console.log("fetch faces success");
        } else {
          console.log("fetch faces error");
        }
      })
      .catch(() => {
        console.log("fetch faces error");
      });
  },
  fetchFacesFromLocal: () => {
    const localStorageFaceModelListStr = localStorage.getItem(
      STORAGE_FACE_MODEL_LIST,
    );
    if (localStorageFaceModelListStr) {
      const faceList = JSON.parse(localStorageFaceModelListStr);
      set((state) => ({
        faces: faceList,
      }));
    }
  },
  cancelFaceModel: () => {
    const uploadingFaceModel = get().uploadingFaceModel;
    if (uploadingFaceModel) {
      if (
        uploadingFaceModel &&
        uploadingFaceModel.status &&
        uploadingFaceModel.status[0] == FaceModelStatus.uploaded
      ) {
        set((state) => ({
          showUploadingDialog: false,
          faceModelLoopQuery: false,
        }));
      } else {
        set((state) => ({
          showUploadingDialog: false,
        }));
      }
    } else {
      set((state) => ({
        showUploadingDialog: false,
      }));
    }
  },
  showFaceModelInfo: () => {
    set((state) => ({ showFaceModelInfoDialog: true }));
  },
  hideFaceModelInfo: () => {
    set((state) => ({ showFaceModelInfoDialog: false }));
  },
  showHint: (msg) => {
    toast.error(msg);
  },
}));
