import axios, { AxiosRequestConfig } from "axios";
import { ApiError } from "./ApiError";
import ApiServices from "./ApiServices";
import { debug } from "util";
import { LOGIN_CODE } from "../constant";
import { clearUserLogout } from "../stores/UserStores";
//test server
// const baseURL = 'https://deloris.natappvip.cc/api'
//local
//const baseURL = 'https://ddd.natappvip.cc/api';
// const baseURL = 'http://127.0.0.1:8000/api';
//online
export const baseURL = "https://api.deloris-ai.com/api";

export const axiosInstance = axios.create({
  timeout: 60000,
});

export let API_TOKEN = "";
export const STORAGE_TOKEN = "TOKEN";

export function updateToken(token: string) {
  const userInfoJson = localStorage.setItem(STORAGE_TOKEN, token);
  API_TOKEN = token;
}
export function clearToken() {
  localStorage.removeItem(STORAGE_TOKEN);
  API_TOKEN = "";
}

axiosInstance.interceptors.request.use((config) => {
  config.headers.set("client", "web");
  config.headers.set("Authorization", "Bearer " + API_TOKEN);
  // config.headers.set('Origin', window.location.origin)
  return config;
});

export interface ResponseWrap<T> {
  code?: number;
  message?: string;
  data: T;
  status: number;
}

axiosInstance.interceptors.request.use(
  function (config) {
    // 在发送请求之前打印URL
    // console.log("request url:" + config.url);

    return config;
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

export function isValidResponse(obj: any): obj is ResponseWrap<any> {
  return (
    obj !== null &&
    typeof obj === "object" &&
    (typeof obj.code === "number" || obj.code === undefined) &&
    (typeof obj.message === "string" || obj.message === undefined)
  );
}

axiosInstance.interceptors.response.use(
  (res) => {
    // console.log("resp url:" + JSON.stringify(res));
    const { data: response = {}, statusText } = res;
    const { code, message } = response as ResponseWrap<any>;
    if (code && LOGIN_CODE.includes(code)) {
      try {
        clearUserLogout();
        window.location.href = `/login`;
      } catch (error) {
        console.log(error, "error");
      }
      return;
    }
    if (code !== undefined && code !== 0 && code !== 200) {
      throw new ApiError({
        code: String(code),
        message: message,
        httpStatus: statusText,
      });
    }
    return response;
  },
  (e) => {
    // console.log("resp url err:" + JSON.stringify(e));
    try {
      const obj = JSON.parse(e.request.response) as any;

      if (e.request && isValidResponse(obj)) {
        const commonResponse: ResponseWrap<any> = obj;
        return commonResponse;
      } else {
        throw new Error(e);
      }
    } catch (err) {
      return { code: -1, message: e.message };
    }
  }
);

export const apiService = new ApiServices<AxiosRequestConfig>({
  baseURL,
  request: (params, options) => {
    return axiosInstance.request({
      ...params,
      ...options,
      headers: {
        ...options?.headers,
        Origin: window.location.origin,
      },
    });
  },
});
