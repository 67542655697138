import { forwardRef, useEffect } from "react";
import { useCreateStores } from "../../stores/CreateStores";
import { LoadingDialog } from "../create/LoadingDoialog";

const FaceUpload = forwardRef<HTMLInputElement>((_, ref) => {
  const { uploadFace, showHint, uploadingFaceModel, queryAnalysisResult } =
    useCreateStores((state) => ({
      uploadFace: state.uploadFace,
      showHint: state.showHint,
      uploadingFaceModel: state.uploadingFaceModel,
      queryAnalysisResult: state.queryAnalysisResult,
    }));

  useEffect(() => {
    if (uploadingFaceModel) {
      queryAnalysisResult();
    }
  }, [uploadingFaceModel]);

  const { showUploadingDialog, uploadingDialogTitle, cancelFaceModel } =
    useCreateStores((state) => ({
      showUploadingDialog: state.showUploadingDialog,
      uploadingDialogTitle: state.uploadingDialogTitle,
      cancelFaceModel: state.cancelFaceModel,
    }));

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!["image/jpeg", "image/png"].includes(files[0].type)) {
        showHint("Only jpg/png format images are allowed for upload.");
        return;
      }
      if (files[0].size > 2 * 1024 * 1024) {
        showHint("The file size cannot exceed 2MB, please choose again.");
        return;
      }
      uploadFace(files[0]);
    }
  };

  return (
    <>
      <input
        type="file"
        onChange={handleChange}
        id="face-upload"
        aria-label="Upload face image"
        ref={ref}
        className="hidden"
      />
      <LoadingDialog
        show={showUploadingDialog}
        title={uploadingDialogTitle}
        msg={""}
        onCancel={() => {
          cancelFaceModel();
        }}
      ></LoadingDialog>
    </>
  );
});

export default FaceUpload;
