export const SCENE = {
  CREATE_IMAGE: "create_image",
  CREATE_CHARACTER: "create_character",
};

export const HOBBIES_AND_PERSONALITY = {
  HOBBIES: "hobbies",
  PERSONALITY: "personality",
};

export const HOBBIES_AND_PERSONALITY_ARR = [
  HOBBIES_AND_PERSONALITY.HOBBIES,
  HOBBIES_AND_PERSONALITY.PERSONALITY,
];

export const PROJECT_STATUS = {
  GENERATING: "generating",
  GENERATED: "image_generated",
  FAILED: "failed",
};

export const APP_ID_DELORIS = "deloris";

export const AUTO_PLAY_KEY = "isAutoPlaying";

export const COST_TYPE = {
  IMAGE_IN_CHAT: "image_in_chat",
  CUSTOM_CHARACTER: "custom_character",
  CREATE_IMAGE_NORMAL: "create_image_normal",
};

export const NEED_SUB_TEXT = '"actions": ["SUBSCRIBE"]';

export const URL_SOUL_CHAT = "/soulChat";
export const URL_AI_CHAT = "/ai-chat";

export const LOGIN_CODE = [100001, 100002, 100003];
