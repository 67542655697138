import { TaskEntity } from "../model/task/TaskEntity";
import { create } from "zustand";
import { apiService } from "../service/Api";
import toast from "react-hot-toast";
import { ProjectEntity, Segment } from "../api/task/TaskApi";

type Action = {
  startGenerated: () => void;
  queryGenerateStatus: () => void;
  confirmContent: () => void;
  resetStatus: () => void;
  displayOriginContent: () => void;
  fetchTaskInfo: (taskId: string | null) => void;
  loopFetchStatus: (taskId: string) => void;
  setImageLoad: (load: boolean) => void;

  setShowDeleteDialog: (open: boolean) => void;

  deleteTask: () => void;
  initTask: (task: TaskEntity) => void;

  setShowTaskClose: (show: boolean) => void;
  setActiveItem: (item: Segment) => void;
};

type State = {
  taskEntity: TaskEntity;
  showNsfwWarm: boolean;
  imageLoaded: boolean;
  errMsg: string;
  showDeleteDialog: boolean;
  redirectJump: boolean;
  loopQuery: boolean;
  deleting: boolean;
  showTaskClose: boolean;
  activeItem: Segment | null;
};

const initStatus: State = {
  taskEntity: {
    status: "INIT",
    nsfw_content: true,
    prompt: "",
    task_id: "1",
    process: 0,
    img_url: "https://liiya.oss-cn-hangzhou.aliyuncs.com/0.png",
  },
  errMsg: "",
  imageLoaded: false,
  showNsfwWarm: true,
  showDeleteDialog: false,
  redirectJump: false,
  loopQuery: false,
  showTaskClose: false,
  deleting: false,
  activeItem: null,
};

export const userTaskStores = create<Action & State>((set, get) => ({
  ...JSON.parse(JSON.stringify(initStatus)),
  startGenerated() {
    get().taskEntity.status = "CREATED";
    set((state) => ({
      taskEntity: state.taskEntity,
    }));
  },
  queryGenerateStatus() {
    get().taskEntity.status = "GENERATING";
    set((state) => ({
      taskEntity: state.taskEntity,
    }));
  },
  confirmContent() {
    get().taskEntity.status = "GENERATED";
    get().taskEntity.img_url =
      "https://liiya.oss-cn-hangzhou.aliyuncs.com/0.png";
    set((state) => ({
      taskEntity: state.taskEntity,
    }));
  },
  displayOriginContent() {
    set((state) => ({
      showNsfwWarm: false,
    }));
  },
  setImageLoad(load: boolean) {
    set((state) => ({
      imageLoaded: load,
    }));
  },
  resetStatus() {
    set(JSON.parse(JSON.stringify(initStatus)));
  },

  async fetchTaskInfo(taskId) {
    let retryCount = 0;
    const taskEntity = get().taskEntity;

    if (!taskId) {
      toast.error("task not exits");
      taskEntity.status = "FAILED";
      set((state) => ({
        taskEntity: taskEntity,
        errMsg: "Error: TaskDetail Not Exits",
      }));
      return;
    } else {
      taskEntity.task_id = taskId;
      set((state) => ({
        loopQuery: true,
        taskEntity: taskEntity,
      }));
    }

    while (retryCount < 30) {
      const loopQuery = get().loopQuery;
      if (!loopQuery) {
        return;
      }
      const response = await apiService.QueryTask({ project_id: taskId });
      if (
        response.data &&
        response.data.project_status &&
        response.code == 200
      ) {
        if (response.data.project_status[1] === "image_generated") {
          taskEntity.img_url = response.data.project_segments[0].image_url;
          taskEntity.status = "GENERATED";
          taskEntity.prompt = response.data.project_segments[0].text;
          taskEntity.projectEntity = response.data;
          set((state) => ({
            showNsfwWarm: false,
            taskEntity: state.taskEntity,
          }));
          // toast.success(`${response.message}`);
          return;
        } else {
          get().taskEntity.status = "GENERATING";
          set((state) => ({
            taskEntity: state.taskEntity,
          }));
        }
      } else {
        taskEntity.status = "FAILED";
        set((state) => ({
          taskEntity: taskEntity,
          errMsg: "Error: Fetch TaskDetail Info Error",
        }));
      }
      await new Promise((r) => setTimeout(r, 2000)); //
    }
  },
  initTask(task) {
    set((status) => ({
      taskEntity: task,
      imageLoaded: false,
      showNsfwWarm: false,
      showDeleteDialog: false,
    }));
  },
  deleteTask() {
    const taskEntity = get().taskEntity;
    set((state) => ({
      deleting: true,
    }));
    apiService
      .Delete({
        id: taskEntity.task_id,
      })
      .then((response) => {
        if (response && response.data && response.code === 200) {
          set((state) => ({
            showDeleteDialog: false,
            deleting: false,
            redirectJump: true,
          }));
          toast.success("Delete Success");
        } else {
          set((state) => ({
            deleting: false,
          }));
          toast.error("Delete Failed");
        }
      })
      .catch(() => {
        set((state) => ({
          deleting: false,
        }));
        toast.error("Delete Failed");
      });
  },
  setActiveItem(activeItem: Segment) {
    set((state) => ({
      activeItem: activeItem,
    }));
  },

  setShowDeleteDialog(open: boolean) {
    set((state) => ({
      showDeleteDialog: open,
    }));
  },
  setShowTaskClose(close: boolean) {
    set((state) => ({
      showTaskClose: close,
    }));
  },
}));
