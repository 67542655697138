import { Box, IconButton, CircularProgress } from "@mui/material";
import { useState } from "react";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

interface ImageCarouselProps {
  images: string[];
  width?: number | string;
  height?: number | string;
  loading?: boolean;
}

export default function ImageCarousel({
  images,
  width = 268,
  height = 476,
  loading = false,
}: ImageCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);

  // 添加空数组判断
  if (images.length === 0 && loading) {
    return (
      <Box
        sx={{
          position: "relative",
          width,
          height,
          overflow: "hidden",
          borderRadius: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          bgcolor: "rgba(0, 0, 0, 0.1)",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // 创建一个包含前后额外图片的数组
  const extendedImages = [images[images.length - 1], ...images, images[0]];

  const handlePrevious = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    if (currentIndex === 0) {
      setCurrentIndex(images.length);
      // 立即重置到正确位置，无动画
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentIndex(images.length - 1);
      }, 0);
    } else {
      setCurrentIndex((prev) => prev - 1);
      setTimeout(() => setIsAnimating(false), 300); // 动画结束后重置状态
    }
  };

  const handleNext = () => {
    if (isAnimating) return;
    setIsAnimating(true);

    if (currentIndex === images.length - 1) {
      setCurrentIndex(images.length);
      // 等待动画完成后重置到第一张
      setTimeout(() => {
        setIsAnimating(false);
        setCurrentIndex(0);
      }, 300);
    } else {
      setCurrentIndex((prev) => prev + 1);
      setTimeout(() => setIsAnimating(false), 300);
    }
  };

  return (
    <Box
      key={images.join(",")}
      sx={{
        position: "relative",
        width,
        height,
        overflow: "hidden",
        borderRadius: 2,
        "&:hover .carousel-arrow": {
          opacity: 1,
        },
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          transition: isAnimating ? "transform 0.3s ease-in-out" : "none",
          transform: `translateX(-${(currentIndex + 1) * 100}%)`,
        }}
      >
        {extendedImages.map((image, index) => (
          <Box
            key={index}
            component="img"
            src={image}
            sx={{
              width: "100%",
              height: "100%",
              flexShrink: 0,
              objectFit: "cover",
            }}
          />
        ))}
      </Box>

      <IconButton
        onClick={handlePrevious}
        disabled={isAnimating}
        className="carousel-arrow"
        sx={{
          position: "absolute",
          left: 8,
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "rgba(0, 0, 0, 0.4)",
          color: "white",
          opacity: 0,
          transition: "opacity 0.2s",
          "&:hover": {
            bgcolor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <NavigateBeforeIcon />
      </IconButton>

      <IconButton
        onClick={handleNext}
        disabled={isAnimating}
        className="carousel-arrow"
        sx={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "rgba(0, 0, 0, 0.4)",
          color: "white",
          opacity: 0,
          transition: "opacity 0.2s",
          "&:hover": {
            bgcolor: "rgba(0, 0, 0, 0.6)",
          },
        }}
      >
        <NavigateNextIcon />
      </IconButton>

      <Box
        sx={{
          position: "absolute",
          bottom: 16,
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          gap: 1,
        }}
      >
        {images.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              bgcolor:
                index === currentIndex ? "white" : "rgba(255, 255, 255, 0.5)",
              cursor: "pointer",
            }}
            onClick={() => !isAnimating && setCurrentIndex(index)}
          />
        ))}
      </Box>
    </Box>
  );
}
