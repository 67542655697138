import { Spinner } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export interface IProps {
  errorMsg: string;
}

export function PageEmpty(props: IProps) {
  const { errorMsg } = props;
  const navigate = useNavigate();
  const toCreate = () => {
    navigate("/create");
  };
  const { t } = useTranslation();
  return (
    <div className="absolute flex flex-col inset-0 flex items-center justify-center">
      <div className="text-white text-[22px] mt-[10px]">{errorMsg}</div>
      <div
        onClick={toCreate}
        className="cursor-pointer select-none pl-[10px]  pr-[10px] bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray  border-gray rounded-[10px] h-[50px] mt-[40px]"
      >
        <div className="text-white text-[18px] w-[260px]">{t("to_create")}</div>
      </div>
    </div>
  );
}
