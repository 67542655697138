import { Avatar } from "@material-tailwind/react";
import React, { useState } from "react";
import { useUserStores } from "../../stores/UserStores";
import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Button,
  Card,
  Typography,
} from "@material-tailwind/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { CursorArrowRaysIcon } from "@heroicons/react/24/solid";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import i18n from "../../react-i18next/i18n";

export function LanguagesMenu() {
  const { t } = useTranslation();

  const currentLanguage = () => {
    const lang = i18n.language;
    if (lang === "zh") {
      return "中文";
    } else if (lang == "en") {
      return "English";
    } else if (lang == "fr") {
      return "Français";
    } else if (lang == "pt") {
      return "portugais";
    } else if (lang == "ja") {
      return "ポルトガル語";
    } else if (lang == "de") {
      return "Deutsch";
    }
  };

  const [language, setLanguage] = useState(i18n.language);
  const { userInfo, logout } = useUserStores((state) => ({
    userInfo: state.userEntity,
    logout: state.logout,
  }));
  const [openMenu, setOpenMenu] = React.useState(false);
  const navigate = useNavigate();
  const selectLanguages = (lang: string) => {
    if (lang) {
      i18n.changeLanguage(lang);
      setLanguage(lang);
    }
  };

  const isSelected = (lang: string) => {
    if (lang === language) {
      return true;
    }
    return false;
  };

  return (
    <div className="flex flex-row justify-center items-center ml-[40px] bg-gary ">
      <Menu open={openMenu} handler={setOpenMenu} allowHover>
        <MenuHandler>
          <div className="flex font-bold items-center gap-3 text-white  capitalize tracking-normal bg-gary ">
            {currentLanguage()}
            <ChevronDownIcon
              strokeWidth={2.5}
              color={"white"}
              className={`h-3.5  w-3.5 transition-transform ${
                openMenu ? "rotate-180" : ""
              }`}
            />
          </div>
        </MenuHandler>
        <MenuList className="bg-gray w-[210px]" placeholder={""}>
          <MenuItem
            placeholder={""}
            className={`${
              isSelected("en")
                ? "bg-gray-selected text-white"
                : " bg-gary text-white"
            } flex items-center gap-2 hover:bg-gray-selected hover:text-white`}
          >
            <div
              onClick={() => {
                selectLanguages("en");
              }}
              className="text-[18px]   font-medium flex flex-row justify-center items-center normal-case  w-full  h-[40px] "
            >
              English
            </div>
          </MenuItem>
          <MenuItem
            placeholder={""}
            className={`${
              isSelected("fr")
                ? "bg-gray-selected text-white"
                : " bg-gary text-white"
            } flex items-center gap-2 hover:bg-gray-selected hover:text-white`}
          >
            <div
              onClick={() => {
                selectLanguages("fr");
              }}
              className="text-[18px]   font-medium flex flex-row justify-center items-center normal-case  w-full  h-[40px] "
            >
              Français
            </div>
          </MenuItem>
          <MenuItem
            placeholder={""}
            className={`${
              isSelected("de")
                ? "bg-gray-selected text-white"
                : " bg-gary text-white"
            } flex items-center gap-2 hover:bg-gray-selected hover:text-white`}
          >
            <div
              onClick={() => {
                selectLanguages("de");
              }}
              className="text-[18px]   font-medium flex flex-row justify-center items-center normal-case  w-full  h-[40px] "
            >
              Deutsch
            </div>
          </MenuItem>
          <MenuItem
            placeholder={""}
            className={`${
              isSelected("pt")
                ? "bg-gray-selected text-white"
                : " bg-gary text-white"
            } flex items-center gap-2 hover:bg-gray-selected hover:text-white`}
          >
            <div
              onClick={() => {
                selectLanguages("pt");
              }}
              className="text-[18px]   font-medium flex flex-row justify-center items-center normal-case  w-full  h-[40px] "
            >
              Portugais
            </div>
          </MenuItem>
          <MenuItem
            placeholder={""}
            className={`${
              isSelected("ja")
                ? "bg-gray-selected text-white"
                : " bg-gary text-white"
            } flex items-center gap-2 hover:bg-gray-selected hover:text-white`}
          >
            <div
              onClick={() => {
                selectLanguages("ja");
              }}
              className="text-[18px]   font-medium flex flex-row justify-center items-center normal-case  w-full  h-[40px] "
            >
              日本語
            </div>
          </MenuItem>
        </MenuList>
      </Menu>
    </div>
  );
}
