import React from "react";

export default function ChatIcon() {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <defs>
        <linearGradient x1="0%" y1="0%" x2="100%" y2="100%" id="chat_svg__a">
          <stop stop-color="rgba(255,255,255,0.8)" offset="0%"></stop>
          <stop stop-color="rgba(255,255,255,0.8)" offset="100%"></stop>
        </linearGradient>
      </defs>
      <path
        d="M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5H6.75a5.25 5.25 0 0 1-5.25-5.25V12C1.5 6.201 6.201 1.5 12 1.5zm-3 9a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm6 0a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3z"
        fill="white"
        fill-rule="evenodd"
      ></path>
    </svg>
  );
}
