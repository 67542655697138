import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  SwipeableDrawer,
} from "@mui/material";
import { Button } from "@material-tailwind/react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserStores } from "../../stores/UserStores";
import { useTranslation } from "react-i18next";

type Anchor = "top" | "left" | "bottom" | "right";

interface IProps {
  onClose: () => void;
}

export function Drawer(props: IProps) {
  const { onClose } = props;
  const { userInfo, initUserInfo, logout } = useUserStores((state) => ({
    userInfo: state.userEntity,
    initUserInfo: state.initUserInfo,
    logout: state.logout,
  }));

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const navigate = useNavigate();
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const { t } = useTranslation();
  const anchor = "right";
  return (
    <div
      className="w-[300px]"
      role="presentation"
      onClick={() => {
        onClose();
      }}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className="flex flex-col bg-dark-bg h-screen">
        <List>
          {[
            "Create",
            "My Creations",
            "Account Info",
            "Pricing",
            "Face Edit",
            "Soul Chat",
          ].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  toggleDrawer(anchor, false);
                  if (index == 0) {
                    navigate("/create");
                  } else if (index == 1) {
                    navigate("/my-creations");
                  } else if (index == 2) {
                    navigate("/account-info");
                  } else if (index == 3) {
                    navigate("/subscribe");
                  } else if (index == 4) {
                    navigate("face-edit");
                  } else if (index == 5) {
                    navigate("/soulChat");
                  }
                }}
              >
                <div className="text-white font-bold">{text}</div>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider className="bg-gray" />
        {!userInfo && (
          <div className="flex flex-col w-full mt-[10px] pl-[5%] pr-[5%] justify-center items-center">
            <Button
              onClick={() => {
                // toggleDrawer('right', false)
                navigate("/login?mode=register");
              }}
              className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gradient-to-r from-blue-500 to-pink-500  w-full h-[40px]"
              placeholder={""}
            >
              {" "}
              {t("log_in")}
            </Button>

            <Button
              onClick={() => {
                // toggleDrawer('right', false)
                navigate("/login?mode=register");
              }}
              className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gray  w-full  mt-[10px] h-[40px]"
              placeholder={""}
            >
              {" "}
              {t("sign_up")}
            </Button>
          </div>
        )}
        {userInfo && (
          <div className="flex flex-col w-full mt-[10px] pl-[5%] pr-[5%] justify-center items-center">
            <Button
              onClick={() => {
                logout();
                navigate("/login?mode=login");
              }}
              className="text-[18px] font-bold flex flex-row justify-center items-center normal-case bg-gradient-to-r from-blue-500 to-pink-500  w-full h-[40px]"
              placeholder={""}
            >
              {" "}
              {t("log_out")}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}
