import { useMediaQuery } from 'react-responsive';

const useResponsive = () => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 640px)' });
  const isMediumScreen = useMediaQuery({ query: '(max-width: 768px)' });
  const isLargeScreen = useMediaQuery({ query: '(max-width: 1024px)' });
  const isXLargeScreen = useMediaQuery({ query: '(max-width: 1280px)' });
  const isXXLargeScreen = useMediaQuery({ query: '(max-width: 1536px)' });

  return {
    isSmallScreen,
    isMediumScreen,
    isLargeScreen,
    isXLargeScreen,
    isXXLargeScreen,
  };
};

export default useResponsive;
