import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import CallIcon from "@mui/icons-material/Call";
import CloseIcon from "@mui/icons-material/Close";
import i18n from "../../react-i18next/i18n";
import AddButton from "../../components/AddButton";
import { useSoulChatStore } from "../../stores/SoulChatStore";
import AddSoulmateDialog from "../../components/SoulChat/AddSoulmateDialog";
import { useUserStores } from "../../stores/UserStores";
import { APP_ID_DELORIS } from "../../constant";
import { apiService } from "../../service/Api";
import { toast } from "react-hot-toast";
import { firebaseLogEvent } from "../../util/utils";
import {
  EVENT_ACTION_DELETE_SOULMATE,
  EVENT_PAGE_SOUL_CHAT,
} from "../../util/Event";

export default function SoulChat() {
  const { lng } = useParams();
  i18n.changeLanguage(lng);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    getSoulmatesList,
    soulmateList,
    getHistoryChatList,
    historyChatList,
    deleteCharacter,
    initAudio,
    currentAudio,
  } = useSoulChatStore();
  const { userInfo } = useUserStores((state) => ({
    userInfo: state.userEntity,
  }));
  const [isAddSoulmateDialogOpen, setIsAddSoulmateDialogOpen] = useState(false);

  useEffect(() => {
    firebaseLogEvent(EVENT_PAGE_SOUL_CHAT);
    if (!soulmateList.length) {
      getSoulmatesList({
        app_id: APP_ID_DELORIS,
        is_system_template: true,
      });
    }
    if (!currentAudio) {
      initAudio();
    }
  }, []);

  useEffect(() => {
    if (userInfo) {
      getHistoryChatList();
    }
  }, [userInfo]);

  return (
    <div className="min-h-screen sm:px-10 sm:mt-10 mt-2 overflow-y-auto px-2">
      {/* Header */}
      <div className="flex gap-4 flex-nowrap overflow-x-auto">
        <div className="w-24 flex flex-col items-center justify-center gap-2 pb-2 sm:pb-10">
          <AddButton
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              e.preventDefault();
              if (!userInfo) {
                navigate(`/login`, { state: { origin: "soul_chat_page" } });
                return;
              }
              if ((userInfo?.entitlement?.member_level?.[0] ?? 0) > 0) {
                setIsAddSoulmateDialogOpen(true);
              } else {
                navigate("/subscribe");
              }
            }}
            isPro={true}
          />
          <span className="text-xs text-white">Add Soulmates</span>
        </div>
        <div className="flex-1 overflow-x-auto">
          <div className="flex gap-6 pb-4 overflow-x-auto">
            {historyChatList.map((item) => (
              <div
                key={item.id}
                className="relative group flex flex-col items-center cursor-pointer w-[80px] pt-2"
                onClick={() => navigate(`/ai-chat?id=${item.id}`)}
              >
                <div className="relative w-16 h-16 mb-2">
                  <div className="absolute -inset-2 rounded-full border-2 border-transparent group-hover:border-primary-color transition-colors duration-200"></div>
                  <div className="w-16 h-16 rounded-full overflow-hidden">
                    <img
                      src={item.icon}
                      alt={item.bot_name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                  <div className="absolute -top-3 right-1 hidden sm:group-hover:block cursor-pointer bg-primary-color text-white rounded-full">
                    <CloseIcon
                      sx={{
                        fontSize: 16,
                      }}
                      onClick={async (e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        firebaseLogEvent(EVENT_ACTION_DELETE_SOULMATE);
                        const resp = await deleteCharacter(item.id);
                        if (resp) {
                          toast.success("Delete success");
                        } else {
                          toast.error("Delete failed");
                        }
                      }}
                    />
                  </div>
                </div>
                <span className="text-xs text-white text-center truncate w-full">
                  {item.bot_name}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* Soulmates List */}
      <div className="grid grid-cols-2 gap-4 2xl:grid-cols-5 xl:grid-cols-4 lg:grid-cols-3 py-3">
        {soulmateList.map((soulmate) => (
          <div
            className="w-full rounded-2xl overflow-hidden bg-white relative cursor-pointer group"
            onClick={() => {
              if (!userInfo) {
                navigate(`/login`, { state: { origin: "soul_chat_page" } });
                return;
              }
              apiService
                .createChat({ bot_template: soulmate.id })
                .then((res) => {
                  if (res.code === 200 && res.data.id) {
                    navigate(`/ai-chat?id=${res.data.id}`);
                  }
                });
            }}
          >
            {/* image */}
            <div className="flex items-center justify-center">
              <img
                src={soulmate.icon}
                alt={soulmate.title}
                className="w-full object-cover"
              />
            </div>
            {/* identity */}
            {soulmate.character_identity && (
              <div className="absolute top-1 left-1">
                <div className="h-6 whitespace-nowrap rounded-xl rounded-br-md px-1.5 text-xs leading-6 backdrop-blur-[10px] bg-[#0006] text-white ">
                  {soulmate.character_identity}
                </div>
              </div>
            )}

            <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black to-transparent">
              <div className="flex h-full w-full flex-col p-3 sm:gap-3">
                <div className="mb-0.5 flex items-center gap-1  xl:mb-1 justify-between">
                  <div className="flex-1 text-xs sm:text-base font-semibold xl:text-xl text-left text-white">
                    {soulmate.title}
                  </div>
                  {/* <div className="text-xs text-gray-500 cursor-pointer">
                    <CallIcon className="text-white" />
                  </div> */}
                </div>
                <div className="text-xs text-white/95 line-clamp-2 text-left">
                  {soulmate.description}
                </div>
                <div className="hidden flex-wrap gap-1  group-hover:sm:flex transition-opacity duration-200">
                  {soulmate?.character_tags?.hobbies
                    ?.concat(soulmate?.character_tags?.personality)
                    ?.map((tag) => (
                      <div className="h-6 whitespace-nowrap rounded-xl px-1.5 text-xs leading-6 bg-[#343232d9] text-white">
                        {tag}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {isAddSoulmateDialogOpen && (
        <AddSoulmateDialog
          open={isAddSoulmateDialogOpen}
          onClose={() => {
            setIsAddSoulmateDialogOpen(false);
          }}
        />
      )}
    </div>
  );
}
