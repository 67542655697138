import React from "react";
import { useTranslation } from "react-i18next";

export function ProBenefitInfo() {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col justify-center items-center lg:w-[50%]">
      <div className="flex flex-row justify-center h-[50px]">
        <div className="text-[24px] font-bold flex flex-row justify-center items-center text-primary-light-color">
          {t("exclusive_discount_only_today")}
        </div>
      </div>
      <div>
        <div className="flex flex-row text-white text-[16px] ">
          <div>{t("up_to")}</div>
          <div className="text-primary-light-color  text-[17px]"> 80% </div>
          <div>{t("off_for_first_subscription")}</div>
        </div>
      </div>
      <div className="flex flex-row justify-center h-[50px]">
        <div className="text-[24px] font-bold text-white flex flex-row justify-center items-center">
          <div className="font-bold">{t("pro_benefit")}</div>
        </div>
      </div>
      <div className="flex flex-col items-start items-center text-[16px]  w-full ">
        <div className="flex flex-col whitespace-nowrap lg:flex-row justify-between w-full ">
          <div className="text-white flex-1 flex justify-start mt-[5px]">
            {t("100_credits_per_month")}
          </div>
          <div className="text-white flex-1 flex justify-start mt-[5px]">
            {t("unlock_multitask")}
          </div>
        </div>
        <div className="flex flex-col whitespace-nowrap lg:flex-row justify-between w-full">
          <div className="text-white flex-1 flex justify-start mt-[5px]">
            {t("priority_queue")}
          </div>
          <div className="text-white flex-1 flex justify-start mt-[5px]">
            {t("face_swap_image_generation")}
          </div>
        </div>
      </div>
    </div>
  );
}
