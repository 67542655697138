import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import React from "react";

interface IProps {
  onDismiss?: () => void;
}

export function ButtonCloseTask(iProps: IProps) {
  const { onDismiss } = iProps;
  return (
    <div onClick={onDismiss} className="cursor-pointer">
      <FontAwesomeIcon
        size={"3x"}
        color={"rgba(1,1,1,0.3)"}
        icon={faCircleXmark}
      ></FontAwesomeIcon>
    </div>
  );
}
