import {
  Button,
  Menu,
  MenuHandler,
  MenuItem,
  MenuList,
} from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faClockRotateLeft,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { useCreateStores } from "../../stores/CreateStores";
import { useTranslation } from "react-i18next";

export function PromptHistory() {
  const { getHistoryTags, historyTags, updatePrompt } = useCreateStores(
    (state) => ({
      configEntity: state.configEntity,
      getHistoryTags: state.getHistoryTags,
      addHistoryTag: state.addHistoryTag,
      historyTags: state.historyTags,
      updatePrompt: state.updatePrompt,
    }),
  );
  useEffect(() => {
    getHistoryTags();
  }, []);
  const hasHistoryData = () => {
    if (historyTags && historyTags.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const recoverPrompt = (historyPrompt: string) => {
    updatePrompt(historyPrompt);
  };
  const { t } = useTranslation();
  return (
    <div className="flex flex-row justify-start ml-[10px]">
      <Menu
        placement="bottom-start"
        animate={{
          mount: { y: 0 },
          unmount: { y: 25 },
        }}
      >
        <MenuHandler>
          <Button
            placeholder=""
            className="flex flex-row justify-center items-center normal-case text-white font-bold text-[14px] bg-gray p-0 pl-[10px] pr-[10px] mr-[10px] mt-[10px] h-[26px] rounded-[15px] "
          >
            <FontAwesomeIcon
              icon={faClockRotateLeft}
              size={"sm"}
              color="white"
            ></FontAwesomeIcon>
            <div className="text-white ml-2">{t("history")}</div>
          </Button>
        </MenuHandler>
        <MenuList className="bg-gray  text-white border-0" placeholder={""}>
          {hasHistoryData() &&
            historyTags.map((item) => {
              return (
                <MenuItem
                  placeholder={""}
                  onClick={() => {
                    recoverPrompt(item.content);
                  }}
                >
                  <div className="flex flex-row items-center max-w-[420px] lg:max-w-[620px] ">
                    <FontAwesomeIcon
                      icon={faClockRotateLeft}
                      size={"sm"}
                      color="white"
                    ></FontAwesomeIcon>
                    <div className="ml-2 text-[17px] overflow-hidden text-ellipsis whitespace-nowrap">
                      {item.content}
                    </div>
                  </div>
                </MenuItem>
              );
            })}
          {!hasHistoryData() && (
            <MenuItem placeholder={""}>{t("no_history_data")}</MenuItem>
          )}
        </MenuList>
      </Menu>
    </div>
  );
}
