import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleQuestion,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

interface IProps {
  show: boolean;
  msg: string;
  onCancel: () => void;
}

export function FaceModelInfoDialog(props: IProps) {
  const { show, msg, onCancel } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      onBlur={() => {
        onCancel();
      }}
      placeholder={""}
      size={"xs"}
      open={show}
      handler={(e) => {}}
      className="bg-dark-bg max-w[100px] w-[100px]"
    >
      <DialogBody placeholder={""} className="text-white">
        <div className="flex flex-col  items-center">
          <div className="text-white text-[20px] font-bold">
            {t("how_to_make_a_quality_creation")}
          </div>
          <div className="text-white text-[14px]">
            {t(
              "please_upload_photo_with_clear_frontal_face_poor_quality_photo_will_lead_to_bad_creation",
            )}
          </div>

          <div className="bg-green-alpha flex flex-col w-full pl-[10px] pr-[10px] rounded-[8px] pb-[15px] pt-[10px] mt-[15px]">
            <div className={"flex flex-row justify-between items-center"}>
              <div className={"font-bold text-green-400  text-[18px]"}>
                {t("good_examples")}
              </div>
              <FontAwesomeIcon
                icon={faCircleCheck}
                size={"lg"}
                color="green"
                className="ml-[3px]"
              ></FontAwesomeIcon>
            </div>
            <div className="flex flex-row justify-between pl-[5px] pr-[5px] mt-[5px]">
              <img
                className={`border-green-400 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/g1.png"
                }
              ></img>
              <img
                className={`border-green-400 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/g2.png"
                }
              ></img>
              <img
                className={`border-green-400 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/g3.png"
                }
              ></img>
            </div>
          </div>

          <div className="bg-red-alpha flex flex-col w-full pl-[10px] pr-[10px] rounded-[8px] pb-[15px] pt-[10px] mt-[15px]">
            <div className={"flex flex-row justify-between items-center"}>
              <div className={"font-bold text-red-600  text-[18px]"}>
                {t("bad_examples")}
              </div>
              <FontAwesomeIcon
                icon={faCircleXmark}
                size={"lg"}
                color="red"
                className="ml-[3px]"
              ></FontAwesomeIcon>
            </div>
            <div className="flex flex-row justify-between pl-[5px] pr-[5px] mt-[5px]">
              <img
                className={`border-red-600 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/b1.png"
                }
              ></img>
              <img
                className={`border-red-600 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/b2.png"
                }
              ></img>
              <img
                className={`border-red-600 border-[2px] rounded-[10px]  w-[80px] h-[80px] scroll-snap-align-start`}
                src={
                  "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/b3.png"
                }
              ></img>
            </div>
          </div>

          <Button
            placeholder={""}
            onClick={() => {
              onCancel();
            }}
            className="relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-[50%]  h-500 border-gray border-4 rounded-[30px] h-[50px] mt-[30px] "
          >
            <div>{t("ok")}</div>
          </Button>
        </div>
      </DialogBody>
    </Dialog>
  );
}
