import { PromptTagEntity } from "../model/create/PromptTagEntity";

interface IProps {
  onClick: () => void;
  tagEntity: PromptTagEntity;
  showAdd: boolean;
}

export function PromptTag(props: IProps) {
  const { tagEntity, onClick, showAdd } = props;
  return (
    <div
      onClick={onClick}
      className="hover:cursor-pointer hover:shadow-lg flex flex-row justify-center items-center text-white h-[26px] rounded-[20px] bg-gray pl-[10px] pr-[10px] mr-[10px] mt-[10px]"
    >
      <div className="select-none text-white">{tagEntity.name}</div>
      {showAdd && (
        <div className="select-none  right-[0px] top-[0px] ml-[5px] font-bold">
          {" "}
          +
        </div>
      )}
    </div>
  );
}
