import { create } from "zustand";
import {
  ProjectEntity,
  ProjectStatusEnum,
  TasksQueryRequest,
} from "../api/task/TaskApi";
import { FaceModel, FaceModelStatus } from "../model/create/FaceModel";
import { CreateEntity, TaskType } from "../model/create/CreateEntity";
import { apiService } from "../service/Api";
import toast from "react-hot-toast";
import {
  PreSignParamsUrlData,
  UploadVideoResponse,
} from "../api/create/CreateApi";
import { logEvent } from "firebase/analytics";
import { analytics } from "../App";
import {
  ACTION_FACE_EDIT_CREATE,
  ACTION_FACE_EDIT_VIDEO_UPLOAD,
} from "../util/Event";

export const STORAGE_CACHE_FACE_EDIT_PROJECT =
  "STORAGE_CACHE_FACE_EDIT_PROJECT";
//这里定义行为
export type Action = {
  selectModeType: (type: string) => void;
  getUploadImage: () => string;
  setUploadImage: (imageUrl: string) => void;
  fetchTasks: (page?: number, loadMore?: boolean) => void;
  fetchTaskFromCache: () => void;
  deleteTask: () => void;
  uploadVideo: (file: File) => void;
  subGenVideoTask: () => void;
  setModel: (modeEntity: FaceModel) => void;
  resetStatus: () => void;
  uploadFace: (file: File) => void;
  cancelUploadVideo: () => void;
  selectProject: (project: ProjectEntity) => void;
  showOriginPreview(origin: boolean): void;
  setShowDeleteDialog: (open: boolean) => void;
  newProject: () => void;
  queryFaceEditTask: (project_id: string) => void;
  getAwsUrl: () => void;
  updateUploadToAwsProcessing: (
    processing: number,
    estimatedRemainingTime: string,
  ) => void;
};

//这里定义状态
export type State = {
  modelSelected: string;
  promptSelect: string;
  numberOfImag: number;
  rebuildType: string;
  uploadImage: string;

  tasks: any[];
  page: number;
  loadMore: boolean;
  showEmpty: boolean;
  showError: boolean;
  errorMsg: string;
  deleting: boolean;
  deleteSuccess: boolean;
  showDeleteDialog: boolean;
  deleteTaskId: string;
  projects: ProjectEntity[];
  uploadingFaceModel: FaceModel | null;
  uploadingDialogTitle: string;
  faceModelLoopQuery: boolean;
  showUploadingDialog: boolean;
  creatingTask: boolean;
  showErrDialog: boolean;
  errMessage: string;
  errCode: number;
  createEntity: CreateEntity;
  project: ProjectEntity | undefined;
  cacheProjects: ProjectEntity[];
  previewOriginVideo: boolean;
  videoCreateProcessing: boolean;
  awsUrlEntity: PreSignParamsUrlData | null;
  updateUploadToAwsProcessingNum: number;
  estimatedRemainingTime: string;
};

/*
export const useFaceEditStores = create<Action>((set) => ({
    selectModeType: () => set((state) => ({}))
}))
*/

const initStatus: State = {
  project: undefined,
  modelSelected: "man",
  promptSelect: "",
  numberOfImag: 1,
  rebuildType: "edit",
  uploadImage: "",
  createEntity: {
    project_id: "",
    bears: 0,
    prompt: "",
    promptTags: [],
    style: { index: 0, name: "", image: "", model_name: "" },
    model: {
      index: 0,
      name: "Real Girl",
      model_name: "edge-of-realism",
      image:
        "https://s3.us-east-2.amazonaws.com/www.deloris-ai.com/example/20240212-210356.jpg",
    },
    batchNumber: 1,
    faceModel: {
      id: "",
      detection_result: false,
      index: -1,
      resize_url:
        "https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/MnNIMybLzD/a63534ed-a901-4e8b-baeb-3e448597ccf6.jpeg",
      url: "https://picso-files.faceplay.me/cdn-cgi/image/width=128/https://d1owhbh94qrb43.cloudfront.net/US/20230315/MnNIMybLzD/a63534ed-a901-4e8b-baeb-3e448597ccf6.jpeg",
    },
  },
  creatingTask: false,
  errCode: 0,
  errMessage: "",
  showErrDialog: false,
  deleteSuccess: false,
  deleteTaskId: "",
  deleting: false,
  errorMsg: "",
  projects: [],
  showDeleteDialog: false,
  showEmpty: false,
  showError: false,
  loadMore: false,
  page: 1,
  tasks: [],
  cacheProjects: [],
  uploadingFaceModel: null,
  uploadingDialogTitle: "Uploading",
  faceModelLoopQuery: false,
  showUploadingDialog: false,
  previewOriginVideo: true,
  videoCreateProcessing: false,
  awsUrlEntity: null,
  updateUploadToAwsProcessingNum: 0,
  estimatedRemainingTime: "",
};

export const useFaceEditStores = create<Action & State>((set, get) => ({
  ...JSON.parse(JSON.stringify(initStatus)),
  selectModeType(type) {
    //可以使用 get() 获取当前的状态
    let selected = get().modelSelected;
    console.log(`selected = ${selected}`);
    //使用 set 更新状态。
    set((state) => ({
      //待更新的状态写在前面

      modelSelected: type,
    }));
  },
  getUploadImage() {
    return get().uploadImage;
  },
  setUploadImage(imageUrl) {
    set((state) => ({
      //待更新的状态写在前面
      uploadImage: imageUrl,
    }));
  },

  subGenVideoTask: async () => {
    if (
      !get().createEntity.faceModel ||
      get().createEntity.faceModel.id === ""
    ) {
      toast.error("Please Select or Upload Face");
      return;
    }
    const curProject = get().project;
    if (!curProject) {
      toast.error("Please upload a video first");
      return;
    }
    const statusCode = curProject.project_status[0];
    if (
      statusCode == ProjectStatusEnum.VideoAnalysing ||
      statusCode == ProjectStatusEnum.SwapGenerating
    ) {
      toast.error("The current project is being processed, please wait");
      return;
    }
    const createEntity: CreateEntity = get().createEntity;
    set((state) => ({
      creatingTask: true,
    }));

    const project = get().project;
    apiService
      .SubmitVideoTask({
        id: project ? project.id : "",
        project_name: "video task",
        project_type: TaskType.video,
        project_novel_text: createEntity.prompt,
        project_config: {
          sd_model_name: createEntity.model.model_name,
          sd_image_ratio: "2:3",
          batch_number: createEntity.batchNumber,
          lora_model_name: createEntity.model.name,
          face_model_id:
            createEntity.faceModel && createEntity.faceModel.id !== ""
              ? createEntity.faceModel.id
              : null,
          voice_name: "",
          voice_speed: 1,
          voice_volume: 11,
          background_music_url: "",
        },
      })
      .then((response) => {
        logEvent(analytics, ACTION_FACE_EDIT_CREATE, {});
        if (response.code === 200) {
          if (response.data) {
            createEntity.project_id = response.data.id;
          }
          set((state) => ({
            creatingTask: false,
            videoCreateProcessing: true,
          }));
          get().fetchTasks(1, false);
          get().queryFaceEditTask(response.data.id);
        } else if (
          response.code === 200005 ||
          response.code === 600001 ||
          response.code === 200004
        ) {
          set((state) => ({
            creatingTask: false,
            createEntity: createEntity,
            errCode: response.code,
            errMessage: response.message,
            videoCreateProcessing: false,
            gotoSubscribe: true,
          }));
          toast.error(`${response.message}`);
        } else {
          toast.error(`${response.message}`);
          set((state) => ({
            creatingTask: false,
            createEntity: createEntity,
            errCode: response.code,
            errMessage: response.message,
            videoCreateProcessing: false,
          }));
        }
      });
  },
  async fetchTasks(page: number, loadMore: boolean) {
    const projects: ProjectEntity[] = loadMore ? get().projects : [];
    set((state) => ({
      showQuerying: true,
      querying: true,
      showEmpty: false,
      showError: false,
      errorMsg: "",
      showDeleteDialog: false,
      deleting: false,
      deleteTaskId: "",
      showDetail: false,
    }));
    const currentPage = get().page;
    const req: TasksQueryRequest = {
      page: page ? page : currentPage,
      size: 50,
      project_type: TaskType.video,
    };
    const response = await apiService.QueryTasks(req);
    if (response.data && response.data && response.code == 200) {
      if (response.data && response.data.data.length !== 0) {
        //本地缓存第一页数据
        if (
          req.page === 1 &&
          response.data &&
          response.data.data.length !== 0
        ) {
          localStorage.setItem(
            STORAGE_CACHE_FACE_EDIT_PROJECT,
            JSON.stringify(response.data.data),
          );
        }
        response.data.data.map((project) => {
          if (project) {
            projects.push(project);
          }
        });
        set((state) => ({
          projects: projects,
          showQuerying: false,
          showError: false,
          showEmpty: false,
          querying: false,
          page: response.data.page,
          has_next_page: response.data.has_next,
        }));
      } else {
        //如果请求的结果为空的情况下，清理本地缓存。
        localStorage.setItem(STORAGE_CACHE_FACE_EDIT_PROJECT, "");
        set((state) => ({
          showEmpty: true,
          showQuerying: false,
          errorMsg: "No Creations",
          showError: false,
          querying: false,
        }));
      }
    } else {
      set((state) => ({
        projects: [],
        showQuerying: false,
        querying: false,
        showEmpty: false,
        showError: true,
        errMsg: "Ops:something wrong",
      }));
    }
  },

  uploadVideo: async (file: File) => {
    set((state) => ({
      showUploadingDialog: true,
      uploadingDialogTitle: "Uploading...",
    }));
    let awsUrlEntity = get().awsUrlEntity;
    if (awsUrlEntity === null) {
      const response = await apiService.GetAwsToken();
      if (response.code === 200) {
        awsUrlEntity = response.data;
        set((state) => ({
          awsUrlEntity: response.data,
        }));
      }
    }
    const awsUrl = awsUrlEntity ? awsUrlEntity.url : "";
    const awsKey = awsUrlEntity ? awsUrlEntity.pre_sign_params.key : "";
    const finalUrl = awsUrl + "/" + awsKey;
    const uploadResponse = apiService.UploadFileByPresignedUrl(
      awsUrlEntity,
      file,
      get().updateUploadToAwsProcessing,
    );
    uploadResponse.then((response) => {
      // console.log(`upload success${JSON.stringify(response)}`);
      if (response.status == 204) {
        apiService
          .UploadAwsanalysisUrl(finalUrl)
          .then((response) => {
            // console.log(`upload UploadAwsanalysisUrl${JSON.stringify(response)}`);
            if (response.code == 200) {
              const createEntity = get().createEntity;
              const projectId = response.data.id;
              createEntity.project_id = projectId;
              set((state) => ({
                createEntity: createEntity,
                project: response.data,
                showUploadingDialog: false,
              }));
              get().getAwsUrl();
              get().fetchTasks();
              get().queryFaceEditTask(projectId);
            } else {
              toast.error("Video Upload Failed");
              set((state) => ({
                showUploadingDialog: false,
              }));
            }
          })
          .catch(() => {
            toast.error("Add Face Model Failed");
            console.log("upload error");
            set((state) => ({
              showUploadingDialog: false,
            }));
          });
      } else {
        toast.error("Video Upload Failed");
        set((state) => ({
          showUploadingDialog: false,
        }));
      }
    });
  },
  fetchTaskFromCache() {
    const cacheProjects = localStorage.getItem(STORAGE_CACHE_FACE_EDIT_PROJECT);
    if (cacheProjects) {
      const projects = JSON.parse(cacheProjects);
      set((state) => ({
        projects: projects,
      }));
    }
  },

  setModel: (modeEntity: FaceModel) => {
    get().createEntity.faceModel = modeEntity;
    set((state) => ({
      createEntity: state.createEntity,
    }));
  },

  resetStatus: () => {
    set(JSON.parse(JSON.stringify(initStatus)));
  },

  cancelUploadVideo: () => {
    const uploadingFaceModel = get().uploadingFaceModel;
    if (uploadingFaceModel) {
      if (
        uploadingFaceModel &&
        uploadingFaceModel.status &&
        uploadingFaceModel.status[0] == FaceModelStatus.uploaded
      ) {
        set((state) => ({
          showUploadingDialog: false,
          faceModelLoopQuery: false,
        }));
      } else {
        set((state) => ({
          showUploadingDialog: false,
        }));
      }
    } else {
      set((state) => ({
        showUploadingDialog: false,
      }));
    }
  },

  uploadFace: (file: File) => {
    set((state) => ({
      showUploadingDialog: true,
      uploadingDialogTitle: "Uploading...",
    }));
    apiService
      .UploadVideo({
        file: file,
      })
      .then((resp: UploadVideoResponse) => {
        if (resp.code == 200) {
          // console.log(`upload success${resp.data}`);
          set((state) => ({
            showUploadingDialog: false,
            project: resp.data,
          }));
        } else {
          toast.error("Add Face Model Failed");
          console.log("upload error");
          set((state) => ({
            showUploadingDialog: false,
          }));
        }
      })
      .catch(() => {
        toast.error("Add Face Model Failed");
        console.log("upload error");
        set((state) => ({
          showUploadingDialog: false,
        }));
      });
  },
  selectProject: (project: ProjectEntity) => {
    let previewOriginVideo = true;
    if (
      project &&
      project.project_segments &&
      project.project_segments[0] &&
      project.project_segments[0].gen_video_url
    ) {
      previewOriginVideo = false;
    }
    set((state) => ({
      project: project,
      videoCreateProcessing: false,
      previewOriginVideo: previewOriginVideo,
    }));
  },
  showOriginPreview: (origin: boolean) => {
    set((state) => ({
      previewOriginVideo: origin,
    }));
  },
  setShowDeleteDialog(open: boolean) {
    set((state) => ({
      showDeleteDialog: open,
    }));
  },
  deleteTask() {
    const taskEntity = get().project;
    set((state) => ({
      deleting: true,
    }));
    apiService
      .Delete({
        id: taskEntity ? taskEntity.id : "",
      })
      .then((response) => {
        if (response && response.data && response.code === 200) {
          set((state) => ({
            showDeleteDialog: false,
            deleting: false,
            redirectJump: true,
            project: undefined,
          }));
          toast.success("Delete Success");
          get().fetchTasks(1, false);
        } else {
          set((state) => ({
            showDeleteDialog: false,
            deleting: false,
          }));
          toast.error("Delete Failed");
        }
      })
      .catch(() => {
        set((state) => ({
          showDeleteDialog: false,
          deleting: false,
        }));
        toast.error("Delete Failed");
      });
  },
  newProject: () => {
    set(() => ({
      project: undefined,
      previewOriginVideo: true,
    }));
  },
  queryFaceEditTask: async (project_id) => {
    let retryCount = 0;
    let localVideoCreateProcessing = get().videoCreateProcessing;
    while (retryCount < 30) {
      const response = await apiService.QueryTask({ project_id: project_id });
      console.log(
        `queryFaceEditTask  retryCount = ${retryCount}  project_id = ${project_id} response = ${JSON.stringify(response)}`,
      );
      if (
        response.data &&
        response.data.project_status &&
        response.code == 200
      ) {
        //更新查询的project在列表的数据
        const projects = get().projects.map((item) => {
          if (item.id == response.data.id) {
            return response.data;
          }
          return item;
        });
        const projectStatus = response.data.project_status[1];

        if (
          projectStatus === "SwapGenerating" ||
          projectStatus === "VideoAnalysing"
        ) {
          set((state) => ({
            projects: projects,
          }));
        } else if (
          projectStatus === "generated" ||
          projectStatus === "VideoAnalysed"
        ) {
          const needShowOriginView = projectStatus === "VideoAnalysed";
          set((state) => ({
            projects: projects,
            videoCreateProcessing: false,
            previewOriginVideo: needShowOriginView,
          }));
          //只有当前选中项目和查询相同才更新
          if (response.data.id == get().project?.id) {
            set((state) => ({
              project: response.data,
            }));
          }
          get().fetchTasks(1, false);
          if (projectStatus == "generated") {
            toast.success("Video Swap Success");
          } else if (projectStatus == "VideoAnalysed") {
            toast.success("Video Anlysed");
          }
          console.log("video is Generated");
          return;
        }
      } else {
        set((state) => ({
          project: response.data,
        }));
        localVideoCreateProcessing = false;
      }
      await new Promise((r) => setTimeout(r, 5000));
      retryCount = retryCount + 1;
    }
    return;
  },

  getAwsUrl: async () => {
    const response = await apiService.GetAwsToken();
    if (response.code === 200) {
      // console.log("getAwsUrl token  = " + JSON.stringify(response.data));
      set((state) => ({
        awsUrlEntity: response.data,
      }));
    }
  },
  updateUploadToAwsProcessing: (
    processing: number,
    estimatedRemainingTime: string,
  ) => {
    console.log(
      `updateUploadToAwsProcessing processing = ${processing} estimatedRemainingTime = ${estimatedRemainingTime}`,
    );
    set((state) => ({
      updateUploadToAwsProcessingNum: processing,
      estimatedRemainingTime: estimatedRemainingTime,
    }));
  },
}));
