import { Spinner } from "@material-tailwind/react";
import { useTranslation } from "react-i18next";

export function Downloading() {
  const { t } = useTranslation();
  return (
    <div className="absolute flex flex-col inset-0 flex items-center invisible lg:visible justify-center">
      <Spinner
        className="h-50 w-50 text-gray-900/50 mr-[10px]"
        color="purple"
      />
      <div className="text-white text-[18px] mt-[10px]">{t("downloading")}</div>
    </div>
  );
}
