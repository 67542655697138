import axios from "axios";
import {
  BotTemplateGroupResponse,
  BotTemplateListResponse,
  CreateCharacterParams,
  CreateCharacterResponse,
  CreateChatResponse,
  CreateMessageRequest,
  CreateMessageResponseData,
  CreateRequest,
  CreateResponse,
  CreateVideoRequest,
  EditCharacterParams,
  GetFacesRequest,
  GetFacesResponse,
  GetGreetingsResponse,
  GetSoulmatesRequest,
  MaterialRequest,
  MaterialResponse,
  PreSignParamsUrlData,
  PreSignParamsUrlDataResponse,
  QueryCharacterDescriptionAndPromptResponse,
  QueryChatDetailResponse,
  QueryFacesRequest,
  QueryFacesResponse,
  QueryHistoryChatListResponse,
  UploadRequest,
  UploadResponse,
  UploadVideoResponse,
  VoiceIdList,
  VoiceIdListResponse,
} from "../api/create/CreateApi";
import {
  TaskDeleteRequest,
  TaskDeleteResponse,
  TaskQueryRequest,
  TaskQueryResponse,
  TasksQueryRequest,
  TasksQueryResponse,
} from "../api/task/TaskApi";
import {
  AuthRequest,
  AuthResponse,
  CreatePaymentRequest,
  CreatePaymentResponse,
  GetProductRequest,
  GetProductResponse,
  GetUserRequest,
  GetUserResponse,
  LoginRequest,
  LoginResponse,
} from "../api/user/UserApi";
import { API_TOKEN, axiosInstance, STORAGE_TOKEN } from "./Api";
import { APP_ID_DELORIS } from "../constant";

// 添加重试函数
async function retryOperation<T>(
  operation: () => Promise<T>,
  maxRetries: number = 3,
  delay: number = 1000
): Promise<T> {
  let lastError: Error;
  
  for (let i = 0; i < maxRetries; i++) {
    try {
      return await operation();
    } catch (error) {
      lastError = error as Error;
      console.warn(`Attempt ${i + 1} failed. Retrying...`, error);
      
      if (i < maxRetries - 1) {
        await new Promise(resolve => setTimeout(resolve, delay * (i + 1))); // 指数退避
      }
    }
  }
  
  throw lastError!;
}

export default class ApiServices<T> {
  private request: any = () => {
    throw new Error("StoryApiService.request is undefined");
  };
  private baseURL: string | ((path: string) => string) = "";

  constructor(options?: {
    baseURL?: string | ((path: string) => string);
    request?<R>(
      params: {
        url: string;
        method: "GET" | "DELETE" | "POST" | "PUT" | "PATCH";
        data?: any;
        params?: any;
        headers?: any;
      },
      options?: T,
    ): Promise<R>;
  }) {
    this.request = options?.request || this.request;
    this.baseURL = options?.baseURL || "";
  }

  private genBaseURL(path: string) {
    return typeof this.baseURL === "string"
      ? this.baseURL + path
      : this.baseURL(path);
  }

  QueryMaterial(req?: MaterialRequest, options?: T): Promise<MaterialResponse> {
    const url = this.genBaseURL("/v1/materials/");
    const method = "GET";
    return this.request({ url, method, params: req }, options);
  }

  CreateTask(req?: CreateRequest, options?: T): Promise<CreateResponse> {
    const url = this.genBaseURL("/v1/projects/create");
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  SubmitVideoTask(
    req?: CreateVideoRequest,
    options?: T,
  ): Promise<CreateResponse> {
    const url = this.genBaseURL("/v1/projects/video/swap");
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  QueryTask(req?: TaskQueryRequest, options?: T): Promise<TaskQueryResponse> {
    const url = this.genBaseURL(`/v1/projects/${req?.project_id}`);
    const method = "GET";
    return this.request({ url, method }, options);
  }

  QueryTasks(
    req?: TasksQueryRequest,
    options?: T,
  ): Promise<TasksQueryResponse> {
    const url = this.genBaseURL(`/v1/projects/fetch`);
    const method = "GET";
    const params = req;
    return this.request({ url, method, params }, options);
  }

  Delete(req?: TaskDeleteRequest, options?: T): Promise<TaskDeleteResponse> {
    const url = this.genBaseURL(`/v1/projects/delete/${req?.id}`);
    const method = "DELETE";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  Auth(req?: AuthRequest, options?: T): Promise<AuthResponse> {
    const url = this.genBaseURL(`/v1/users/auth`);
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  GetUserInfo(req?: GetUserRequest, options?: T): Promise<GetUserResponse> {
    const url = this.genBaseURL(`/v1/users/me`);
    const method = "GET";
    return this.request({ url, method }, options);
  }

  Login(req?: LoginRequest, options?: T): Promise<LoginResponse> {
    const url = this.genBaseURL(`/v1/users/me`);
    const method = "GET";
    return this.request({ url, method, req }, options);
  }

  CreatePayment(
    req: CreatePaymentRequest,
    options?: T,
  ): Promise<CreatePaymentResponse> {
    const url = this.genBaseURL(`/v1/subscription/payment`);
    const method = "POST";
    const data = req;
    return this.request({ url, method, data }, options);
  }

  GetProducts(
    req: GetProductRequest,
    options?: T,
  ): Promise<GetProductResponse> {
    const url = this.genBaseURL("/v1/subscription/products");
    const method = "GET";
    const data = req;
    return this.request({ url, method }, options);
  }

  UploadFace(req: UploadRequest, options?: T): Promise<UploadResponse> {
    const url = this.genBaseURL("/v1/faces/upload");
    const formData = new FormData();
    formData.append("file", req.file);
    return axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + API_TOKEN,
      },
      method: "POST",
    });
  }

  UploadVideo(req: UploadRequest, options?: T): Promise<UploadVideoResponse> {
    const url = this.genBaseURL("/v1/faces/video");
    const formData = new FormData();
    formData.append("file", req.file);
    return axiosInstance.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + API_TOKEN,
      },
      method: "POST",
    });
  }

  QueryFaces(req: QueryFacesRequest, options?: T): Promise<QueryFacesResponse> {
    const url = this.genBaseURL("/v1/faces/query");
    const method = "GET";
    const params = req;
    return this.request({ url, method, params }, options);
  }

  GetFaces(req: GetFacesRequest, options?: T): Promise<GetFacesResponse> {
    const url = this.genBaseURL("/v1/faces/fetch");
    const method = "GET";
    const data = req;
    return this.request({ url, method }, options);
  }

  // 获取 aws 上传 url
  GetAwsToken(): Promise<PreSignParamsUrlDataResponse> {
    const url = this.genBaseURL("/v1/faces/video/url/presigned");
    const method = "GET";
    return this.request({ url, method });
  }

  UploadAwsanalysisUrl(awsUrl: string): Promise<UploadVideoResponse> {
    const url = this.genBaseURL("/v1/faces/video/analysis");
    const method = "POST";
    const data = {
      video_url: awsUrl,
    };
    return this.request({ url, method, data });
  }

  // 通过授权 aws url 上传文件。
  async UploadFileByPresignedUrl(
    preSignParamsUrlData: PreSignParamsUrlData | null,
    file: File,
    callback: (progress: number, estimatedRemainingTime: string) => void,
  ): Promise<any> {
    if (!preSignParamsUrlData) {
      return;
    }

    const formData = new FormData();
    formData.append("key", preSignParamsUrlData.pre_sign_params.key);
    formData.append(
      "x-amz-credential",
      preSignParamsUrlData.pre_sign_params.x_amz_credential,
    );
    formData.append(
      "x-amz-date",
      preSignParamsUrlData.pre_sign_params.x_amz_date,
    );
    formData.append(
      "x-amz-signature",
      preSignParamsUrlData.pre_sign_params.x_amz_signature,
    );
    formData.append("policy", preSignParamsUrlData.pre_sign_params.policy);
    formData.append(
      "x-amz-algorithm",
      preSignParamsUrlData.pre_sign_params.x_amz_algorithm,
    );
    formData.append("file", file);
    const headers = {
      "Content-Type": "multipart/form-data",
    };
    const axiosInner = axios.create({
      timeout: 180000,
    });
    let lastUpdateTime = 0;
    let lastLoaded = 0;
    callback(0, "Calculating...");
    return axiosInner.post(preSignParamsUrlData.url, formData, {
      headers: headers,
      method: "POST",
      onUploadProgress: (progressEvent) => {
        if (lastUpdateTime == 0) {
          lastUpdateTime = Date.now();
          callback(0, "Calculating...");
          return;
        }
        // 首次计算包含建链时间 有加大波动，所以跳过
        const now = Date.now();
        const currentTimeElapsed = now - lastUpdateTime;
        // 计算本次上传花费的时间内上传了多少数据
        const avgSpeed = (progressEvent.loaded / currentTimeElapsed) * 1000;
        // 当前已上传的总百分比
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / (progressEvent.total || 100),
        );
        // console.log(`uploaded total : ${progressEvent.total||progressEvent.loaded}`)
        // 剩余需上传的字节数
        const remainingBytes =
          (progressEvent.total || progressEvent.loaded) - progressEvent.loaded;
        // console.log(`uploaded avgSpeed : ${avgSpeed}`)
        // console.log(`uploaded remainingBytes : ${remainingBytes}`)

        // 根据当前速度估算剩余时间（毫秒），注意处理除以0的情况
        let estimatedRemainingTime = remainingBytes / avgSpeed;
        if (avgSpeed !== 0) {
          estimatedRemainingTime = remainingBytes / avgSpeed;
        } else {
          // 如果avgSpeed为0，说明还没开始上传或上传速度极慢，可设定为0或不处理
          estimatedRemainingTime = 0; // 或者可以显示为"计算中"之类的提示
        }
        lastLoaded = progressEvent.loaded;
        const estimatedRemainingTimeString = `${Math.floor(estimatedRemainingTime)}s`;
        callback(percentCompleted, estimatedRemainingTimeString);
      },
    });
  }
  // 获取人设列表
  GetSoulmatesList(req: GetSoulmatesRequest, options?: T): Promise<BotTemplateListResponse | null> {
    const url = this.genBaseURL("/v1/sys/bot-templates");
    return this.request({ url, method: 'GET', params: req }, options);
  }
  // 创建角色
  createCharacter(data: CreateCharacterParams): Promise<CreateCharacterResponse> {
    return this.request({
      url: this.genBaseURL("/v1/sys/bot-templates"),
      method: "POST",
      data: {
        ...data,
        app_id: APP_ID_DELORIS,
      },
    });
  }
   // 获取语音ID列表
   getVoiceIdList(): Promise<VoiceIdListResponse> {
    return this.request({
      url: this.genBaseURL("/v1/materials/?scene=create_image"),
      method: "GET",
    });
  }
   // 获取角色组
  getGroups(): Promise<BotTemplateGroupResponse> {
    return this.request({
      url: this.genBaseURL(`/v1/sys/bot-template-group?app_id=${APP_ID_DELORIS}`),
      method: "GET",
    });
  }
  // 编辑角色
  editCharacter(data: Partial<EditCharacterParams>) {
    return this.request({
      url: this.genBaseURL("/v1/sys/edit-bot-templates"),
      method: "POST",
      data: {
        ...data,
        app_id: APP_ID_DELORIS,
      },
    });
  }
  // 获取人设描述和人设prompt
  queryCharacterDescriptionAndPrompt(req: { message_id: string }):Promise<QueryCharacterDescriptionAndPromptResponse> {
    return this.request({
      url: this.genBaseURL(`/v1/article/get_seo_article?message_id=${req.message_id}`),
      method: "GET",
    });
  }
  // 根据id创建chat
  createChat(data: {bot_template: string}): Promise<CreateChatResponse> {
    return this.request({
      url: this.genBaseURL("/v1/chats/"),
      method: "POST",
      data,
    });
  }
  // 获取历史人设记录列表
  queryHistoryChatList(): Promise<QueryHistoryChatListResponse> {
    return this.request({
      url: this.genBaseURL(`/v1/chats/?app_id=${APP_ID_DELORIS}`),
      method: "GET",
    });
  }
  // 获取chat详情
  queryChatDetail(chat_id: string, offset?: number): Promise<QueryChatDetailResponse> {
    return this.request({
      url: this.genBaseURL(`/v1/chats/get_chat?chat_id=${chat_id}&offset=${offset || 0}`),
      method: "GET",
    });
  }
  // 聊天api
  createChatMessage(
    req?: CreateMessageRequest,
    options?: T,
  ): Promise<CreateMessageResponseData> {
    return this.request({ url: this.genBaseURL(`/v1/chats/message`), method: "POST", data: req }, options);
  }

  // 删除人设
  deleteCharacter(data: {chat_id?: string}) {
    return this.request({
      url: this.genBaseURL(`/v1/chats/del-chat?chat_id=${data.chat_id}`),
      method: "DELETE",
    });
  }
  // 删除会话记录
  deleteChatHistory(chat_id: string) {
    return this.request({
      url: this.genBaseURL(`/v1/chats/clear-chat-message?chat_id=${chat_id}`),
      method: "DELETE",
    });
  }
  // 获取语音流转成的blobSrc
  async getBlobSrc({content, voice_id, is_welcome_msg, template_id}: {content: string, voice_id: string, is_welcome_msg: boolean, template_id: string}) {
    return retryOperation(async () => {
      const response = await fetch(
        this.genBaseURL(`/v1/chats/speech`),
        {
          method: "POST",
          body: JSON.stringify({
            content,
            voice_id,
            is_welcome_msg,
            template_id,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem(STORAGE_TOKEN)}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const contentType = response.headers.get('content-type') || 'audio/mpeg';
      const blob = await response.blob();
      const audioBlob = new Blob([blob], { type: contentType });
      if (audioBlob.size === 0) {
        throw new Error('Received empty audio blob');
      }

      const blobUrl = URL.createObjectURL(audioBlob);
      return blobUrl;
    });
  }
  // // 聊天后手动保存历史记录
  // saveChatHistory(data: {content: string, chat: string, is_from_bot: boolean}) {
  //   return this.request({
  //     url: this.genBaseURL(`/v1/chats/save_image_message`),
  //     method: "POST",
  //     data,
  //   });
  // }
}

