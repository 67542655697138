import React from "react";
import { Button, Spinner } from "@material-tailwind/react";
import { useNavigate, useParams } from "react-router-dom";
import { Footer } from "../../components/common/Footer";
import i18n from "../../react-i18next/i18n";

export function PrivacyScreen() {
  const { lng } = useParams();
  i18n.changeLanguage(lng);
  const navigator = useNavigate();
  return (
    <div className="flex-col w-full  bg-dark-bg ">
      <div className="text-left text-white bg-dark-purple m-10 rounded-[20px] p-10 ">
        <h1>Deloris Terms of Service</h1> <p>Last updated: January 5, 2024</p>{" "}
        <p>
          Please read these terms of service ("terms", "terms of service")
          carefully before using deloris.com (the "Service") operated by Deloris
          ("us", 'we", "our").
        </p>{" "}
        <h2>Conditions of Use</h2>{" "}
        <p>
          We will provide their Service to you, which includes the use of our
          website and mobile applications, subject to the rules and conditions
          set forth in these Terms of Service. By accessing or using the Service
          you agree to be bound by these Terms. If you do not agree to all the
          terms and conditions of this agreement, then you may not access the
          website or use any services.
        </p>{" "}
        <h2>User Accounts</h2>{" "}
        <p>
          When you create an account with us, you guarantee that you are above
          the age of 18, and that the information you provide us is accurate,
          complete, and current at all times. Inaccurate, incomplete, or
          obsolete information may result in the immediate termination of your
          account on the Service.
        </p>{" "}
        <p>
          You are responsible for maintaining the confidentiality of your
          account and password, including but not limited to the restriction of
          access to your computer and/or account. You agree to accept
          responsibility for any and all activities or actions that occur under
          your account and/or password, whether your password is with our
          Service or a third-party service. You must notify us immediately upon
          becoming aware of any breach of security or unauthorized use of your
          account.
        </p>{" "}
        <h2>Intellectual Property</h2>
        <p>
          The Service and its original content, features and functionality are
          and will remain the exclusive property of Deloris and its licensors.
          The Service is protected by copyright, trademark, and other laws of
          both the United States and foreign countries. Our trademarks and trade
          dress may not be used in connection with any product or service
          without the prior written consent of Deloris.
        </p>{" "}
        <h2>Links To Other Web Sites</h2>{" "}
        <p>
          Our Service may contain links to third party web sites or services
          that are not owned or controlled by Deloris.
        </p>{" "}
        <p>
          Deloris has no control over, and assumes no responsibility for the
          content, privacy policies, or practices of any third party web sites
          or services. We do not warrant the offerings of any of these
          entities/individuals or their websites.
        </p>{" "}
        <p>
          For example, the outlined{" "}
          <a href="https://www.termsfeed.com/blog/sample-terms-service-template/">
            Terms of Service
          </a>{" "}
          have been created using PolicyMaker.io, a free web application for
          generating high-quality legal documents. PolicyMaker’s{" "}
          <a href="https://www.termsfeed.com/terms-service-generator/">
            Terms and Conditions generator
          </a>{" "}
          is an easy-to-use free tool for creating an excellent standard Terms
          of Service template for a website, blog, e-commerce store or app.
        </p>{" "}
        <h2>Termination</h2>{" "}
        <p>
          We may terminate or suspend your account and bar access to the Service
          immediately, without prior notice or liability, under our sole
          discretion, for any reason whatsoever and without limitation,
          including but not limited to a breach of the Terms.
        </p>{" "}
        <p>
          If you wish to terminate your account, you may simply discontinue
          using the Service.
        </p>{" "}
        <p>
          All provisions of the Terms which by their nature should survive
          termination shall survive termination, including, without limitation,
          ownership provisions, warranty disclaimers, indemnity and limitations
          of liability.
        </p>
        <h2>Indemnification</h2>{" "}
        <p>
          You agree to defend, indemnify and hold harmless Deloris and its
          licensee and licensors, and their employees, contractors, agents,
          officers and directors, from and against any and all claims, damages,
          obligations, losses, liabilities, costs or debt, and expenses
          (including but not limited to attorney's fees), resulting from or
          arising out of a) your use and access of the Service, or b) a breach
          of these Terms.
        </p>{" "}
        <h2>Limitation Of Liability</h2>{" "}
        <p>
          In no event shall Deloris, nor its directors, employees, partners,
          agents, suppliers, or affiliates, be liable for any indirect,
          incidental, special, consequential or punitive damages, including
          without limitation, loss of profits, data, use, goodwill, or other
          intangible losses, resulting from (i) your access to or use of or
          inability to access or use the Service; (ii) any conduct or content of
          any third party on the Service; (iii) any content obtained from the
          Service; and (iv) unauthorized access, use or alteration of your
          transmissions or content, whether based on warranty, contract, tort
          (including negligence) or any other legal theory, whether or not we
          have been informed of the possibility of such damage, and even if a
          remedy set forth herein is found to have failed of its essential
          purpose.
        </p>{" "}
        <h2>Disclaimer</h2>{" "}
        <p>
          Your use of the Service is at your sole risk. The Service is provided
          on an "AS IS" and "AS AVAILABLE" basis. The Service is provided
          without warranties of any kind, whether express or implied, including,
          but not limited to, implied warranties of merchantability, fitness for
          a particular purpose, non-infringement or course of performance.
        </p>{" "}
        <p>
          Deloris, its subsidiaries, affiliates, and its licensors do not
          warrant that a) the Service will function uninterrupted, secure or
          available at any particular time or location; b) any errors or defects
          will be corrected; c) the Service is free of viruses or other harmful
          components; or d) the results of using the Service will meet your
          requirements.
        </p>
        <h2>Exclusions</h2>{" "}
        <p>
          Some jurisdictions do not allow the exclusion of certain warranties or
          the exclusion or limitation of liability for consequential or
          incidental damages, so the limitations above may not apply to you.
        </p>{" "}
        <h2>Governing Law</h2>{" "}
        <p>
          These Terms shall be governed and construed in accordance with the
          laws of California, United States, without regard to its conflict of
          law provisions.
        </p>{" "}
        <p>
          Our failure to enforce any right or provision of these Terms will not
          be considered a waiver of those rights. If any provision of these
          Terms is held to be invalid or unenforceable by a court, the remaining
          provisions of these Terms will remain in effect. These Terms
          constitute the entire agreement between us regarding our Service, and
          supersede and replace any prior agreements we might have had between
          us regarding the Service.
        </p>{" "}
        <h2>Changes</h2>{" "}
        <p>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. If a revision is material we will provide at
          least 30 days notice prior to any new terms taking effect. What
          constitutes a material change will be determined at our sole
          discretion.
        </p>{" "}
        <p>
          By continuing to access or use our Service after any revisions become
          effective, you agree to be bound by the revised terms. If you do not
          agree to the new terms, you are no longer authorized to use the
          Service.
        </p>{" "}
        <h2>Contact Us</h2>{" "}
        <p>If you have any questions about these Terms, please contact us.</p>
      </div>
      <Footer></Footer>
    </div>
  );
}
