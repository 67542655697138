import { FC, useState } from "react";
import { Menu, MenuItem, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import VideocamIcon from "@mui/icons-material/Videocam";

interface ChatMenuProps {
  onClearChats?: () => void;
}

const MoreOperate: FC<ChatMenuProps> = ({ onClearChats }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClearChats = () => {
    onClearChats?.();
    handleClose();
  };

  return (
    <>
      <IconButton sx={{ color: "white" }} onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>

      <Menu
        id="chat-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        PaperProps={{
          sx: {
            bgcolor: "#272943",
            color: "white",
            borderRadius: 4,
            py: 0,
          },
        }}
      >
        <MenuItem
          onClick={handleClearChats}
          sx={{
            gap: 1,
            fontSize: "14px",
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <DeleteOutlineIcon />
          Clear Chats
        </MenuItem>
        {/* <MenuItem
          sx={{
            color: "grey.500",
            gap: 1,
            "&:hover": {
              bgcolor: "rgba(255, 255, 255, 0.1)",
            },
          }}
        >
          <VideocamIcon />
          Video Call
          <Box component="span" sx={{ fontSize: "0.8rem", ml: 1 }}>
            Coming soon
          </Box>
        </MenuItem> */}
      </Menu>
    </>
  );
};

export default MoreOperate;
