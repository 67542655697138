import { Button, Spinner } from "@material-tailwind/react";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { useUserStores } from "../../stores/UserStores";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export function FceEditCreateButton() {
  const { subGenVideoTask, project, creatingTask } = useFaceEditStores(
    (state) => ({
      subGenVideoTask: state.subGenVideoTask,
      creatingTask: state.creatingTask,
      project: state.project,
    }),
  );
  const { userInfo } = useUserStores((state) => ({
    userInfo: state.userEntity,
  }));
  const navigation = useNavigate();
  const { t } = useTranslation();
  // 校验用户是否登录，如果没有先去登录。
  const createClick = () => {
    if (userInfo) {
      subGenVideoTask();
    } else {
      navigation(`/login`, { state: { origin: "face_edit_page" } });
    }
  };
  const showCredit = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments[0] &&
      project.project_segments[0].advance_payment &&
      project.project_segments[0].advance_payment > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const credit = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments[0] &&
      project.project_segments[0].advance_payment &&
      project.project_segments[0].advance_payment > 0
    ) {
      return project.project_segments[0].advance_payment;
    } else {
      return 0;
    }
  };

  return (
    <div className="left-3 p-2">
      <Button
        placeholder={""}
        onClick={() => {
          createClick();
        }}
        className="relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-full  border-gray border-4 rounded-[30px] h-[60px] mt-[10px] "
      >
        <div className="flex gap-8">
          {creatingTask && (
            <Spinner
              className="h-6 w-6 text-gray-900/50 mr-[10px]"
              color="purple"
            />
          )}
        </div>
        <div>{t("face_swap")}</div>
        {showCredit() && (
          <div className="absolute right-5 top-0 text-[12px] bg-white text-black pl-2 pr-2  rounded-bl-[10px] rounded-br-[10px] normal-case">
            {credit()} {t("credit")}
          </div>
        )}
      </Button>
    </div>
  );
}
