import {
  Modal,
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VolumeDownIcon from "@mui/icons-material/VolumeDown";
import React, { useEffect, useRef } from "react";
import AddButton from "../../AddButton";
import { useCreateStores } from "../../../stores/CreateStores";
import FaceUpload from "../../FaceUpload";
import { HOBBIES_AND_PERSONALITY_ARR, SCENE } from "../../../constant";
import { useSoulChatStore } from "../../../stores/SoulChatStore";
import CreateButton from "../CreateButton";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";

export default function AddSoulmateDialog({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) {
  const navigate = useNavigate();
  const faceUploadRef = useRef<HTMLInputElement>(null);
  const { queryMaterial, tabs, faces, fetchFaces } = useCreateStores(
    (state) => ({
      queryMaterial: state.queryMaterial,
      tabs: state.tabs,
      faces: state.faces,
      fetchFaces: state.fetchFaces,
    })
  );
  const {
    addSoulmateDialogData,
    setAddSoulmateDialogData,
    voiceIdList,
    getInitData,
    createProcessEnd,
    chat_id,
  } = useSoulChatStore((state) => ({
    addSoulmateDialogData: state.addSoulmateDialogData,
    setAddSoulmateDialogData: state.setAddSoulmateDialogData,
    voiceIdList: state.voiceIdList,
    getInitData: state.getInitData,
    createProcessEnd: state.createProcessEnd,
    chat_id: state.chat_id,
  }));

  useEffect(() => {
    if (!tabs?.length) {
      queryMaterial(SCENE.CREATE_CHARACTER);
    }
    if (!faces?.length) {
      fetchFaces();
    }
    getInitData();
  }, []);

  useEffect(() => {
    if (createProcessEnd && chat_id) {
      onClose();
      navigate(`/ai-chat?id=${chat_id}`);
    }
  }, [createProcessEnd, chat_id, onClose, navigate]);

  const handleTagClick = (tabName: string, tagName: string) => {
    if (HOBBIES_AND_PERSONALITY_ARR.includes(tabName)) {
      if (
        addSoulmateDialogData.personalityAndHobbiesTags.includes(
          `${tabName}__${tagName}`
        )
      ) {
        setAddSoulmateDialogData({
          personalityAndHobbiesTags: [
            ...addSoulmateDialogData.personalityAndHobbiesTags.filter(
              (item) => item !== `${tabName}__${tagName}`
            ),
          ],
        });
        return;
      }
      setAddSoulmateDialogData({
        personalityAndHobbiesTags: [
          ...addSoulmateDialogData.personalityAndHobbiesTags,
          `${tabName}__${tagName}`,
        ],
      });
    } else {
      if (addSoulmateDialogData.selectedOtherTags.includes(tagName)) {
        setAddSoulmateDialogData({
          selectedOtherTags: [
            ...addSoulmateDialogData.selectedOtherTags.filter(
              (item) => item !== tagName
            ),
          ],
        });
        return;
      }
      setAddSoulmateDialogData({
        selectedOtherTags: [
          ...addSoulmateDialogData.selectedOtherTags,
          tagName,
        ],
      });
    }
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        className="max-h-[80vh] overflow-y-auto sm:p-6 p-4"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "90%",
          maxWidth: 600,
          bgcolor: "#2A2B3D",
          borderRadius: 2,
          color: "white",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 3,
          }}
        >
          <Typography variant="h6" className="">
            Add Soulmates
          </Typography>
          <IconButton onClick={onClose} sx={{ color: "white" }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            mb: {
              sm: 3,
              xs: 1,
            },
          }}
        >
          <Typography sx={{ mb: 1 }}>Face</Typography>
          <Box sx={{ display: "flex" }}>
            <AddButton
              onClick={() => {
                faceUploadRef.current?.click();
              }}
            />
            <div className="ml-2 flex flex-nowrap overflow-x-auto flex-shrink-0 sm:max-w-[480px] pb-2 max-w-[calc(100%-80px)]">
              {faces &&
                faces.map((item, index) => {
                  const selected =
                    addSoulmateDialogData.selectedFace?.id === item.id;
                  return (
                    <div
                      className="sm:w-[69px] sm:h-[69px] w-[60px] h-[60px] scroll-snap-align-start ml-[10px] flex-shrink-0"
                      onClick={() =>
                        setAddSoulmateDialogData({ selectedFace: item })
                      }
                      key={item.id}
                    >
                      <img
                        alt="face"
                        className={`border-${
                          selected ? "primary-color" : "gray"
                        } border-[2px] rounded-[35px] sm:w-[69px] sm:h-[69px] w-[60px] h-[60px]`}
                        src={item.resize_url}
                      ></img>
                    </div>
                  );
                })}
            </div>
          </Box>

          <FaceUpload ref={faceUploadRef} />
        </Box>
        {/* Name section */}
        <Box
          sx={{
            mb: {
              sm: 3,
              xs: 1,
            },
          }}
        >
          <Typography sx={{ mb: 1 }}>Name</Typography>
          <Box
            onBlur={(e) => {
              setAddSoulmateDialogData({ soulmate_name: e.target.value });
            }}
            component="input"
            sx={{
              width: "100%",
              bgcolor: "rgba(255,255,255,0.1)",
              border: "none",
              borderRadius: "999px",
              color: "white",
              "&::placeholder": {
                color: "rgba(255,255,255,0.7)",
              },
            }}
            className="sm:p-3 sm:text-base p-2 text-sm"
            placeholder="Display name can be up to 50 characters"
          />
        </Box>

        <Box
          sx={{
            mb: {
              sm: 3,
              xs: 1,
            },
          }}
        >
          <Typography sx={{ mb: 1 }}>Age</Typography>
          <Box
            onBlur={(e) => {
              if (Number(e.target.value) < 18) {
                toast.error("Age must be over 18 years old");
                setAddSoulmateDialogData({ soulmate_age: 18 });
              } else if (Number(e.target.value) > 100) {
                toast.error("Age must be less than 100 years old");
                setAddSoulmateDialogData({ soulmate_age: 100 });
              } else {
                setAddSoulmateDialogData({
                  soulmate_age: Number(e.target.value),
                });
              }
            }}
            value={addSoulmateDialogData.soulmate_age}
            onChange={(e) => {
              setAddSoulmateDialogData({
                soulmate_age: Number(e.target.value),
              });
            }}
            component="input"
            type="number"
            min={18}
            max={100}
            sx={{
              width: "100%",
              bgcolor: "rgba(255,255,255,0.1)",
              border: "none",
              borderRadius: "999px",
              color: "white",
              "&::placeholder": {
                color: "rgba(255,255,255,0.7)",
              },
            }}
            className="sm:p-3 sm:text-base p-2 text-sm"
            placeholder="Must be over 18 years old"
          />
        </Box>

        <Box
          sx={{
            mt: {
              sm: 3,
              xs: 1,
            },
          }}
        >
          {tabs.map((tab, index) => (
            <Box key={tab.name} sx={{ mb: 3 }}>
              <Typography
                sx={{
                  mb: 1.5,
                  color: "white",
                  fontSize: "1rem",
                  fontWeight: 500,
                }}
              >
                {tab.name}
              </Typography>
              <div className="flex flex-wrap gap-2">
                {tab.tags.map((tag, tagIndex) => {
                  const isSelected = HOBBIES_AND_PERSONALITY_ARR.includes(
                    tab.name
                  )
                    ? addSoulmateDialogData?.personalityAndHobbiesTags
                        .map((item) => item.split("__")[1])
                        ?.includes(tag.name)
                    : addSoulmateDialogData?.selectedOtherTags?.includes(
                        tag.name
                      );
                  return (
                    <Box
                      key={tagIndex}
                      onClick={() => handleTagClick(tab.name, tag.name)}
                      sx={{
                        display: "inline-block",
                        padding: "6px 12px",
                        backgroundColor: isSelected
                          ? "rgba(255, 255, 255, 1)"
                          : "rgba(255, 255, 255, 0.1)",
                        borderRadius: "20px",
                        color: isSelected ? "black" : "white",
                        fontSize: "0.875rem",
                        cursor: "pointer",
                        "&:hover": {
                          backgroundColor: isSelected
                            ? "rgba(255, 255, 255, 0.9)"
                            : "rgba(255, 255, 255, 0.2)",
                        },
                      }}
                    >
                      {tag.name}
                    </Box>
                  );
                })}
              </div>
            </Box>
          ))}
        </Box>
        <Box>
          <Typography sx={{ mb: 1 }}>Voice</Typography>
          <Select
            value={addSoulmateDialogData.voice_id}
            sx={{
              borderRadius: "999px",
              width: "100%",
              color: "#fff",
              "& .MuiSvgIcon-root": {
                color: "white",
              },
              "& .MuiSelect-select": {
                padding: {
                  sm: "16px 32px 16px 14px",
                  xs: "6px 12px",
                },
              },
            }}
            MenuProps={{
              anchorOrigin: {
                vertical: "top",
                horizontal: "left",
              },
              transformOrigin: {
                vertical: "bottom",
                horizontal: "left",
              },
              PaperProps: {
                sx: {
                  marginTop: "-8px",
                  bgcolor: "#2A2B3D",
                  color: "#fff",
                },
              },
            }}
            renderValue={(value) => {
              const voice = voiceIdList.find((voice) => voice.id === value);
              return voice?.name || "";
            }}
          >
            {voiceIdList?.map((voiceId) => (
              <MenuItem
                key={voiceId.id}
                value={voiceId.id}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  setAddSoulmateDialogData({ voice_id: voiceId.id });
                }}
              >
                <span>{voiceId.name}</span>
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    const audio = new Audio(voiceId.url);
                    audio.play();
                  }}
                >
                  <VolumeDownIcon />
                </span>
              </MenuItem>
            ))}
          </Select>
        </Box>
        <CreateButton />
      </Box>
    </Modal>
  );
}
