import { Button } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faCopy,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import React, { useEffect } from "react";
import { userTaskStores } from "../../stores/TaskStores";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useCreateStores } from "../../stores/CreateStores";
import { Segment } from "../../api/task/TaskApi";
import { useTranslation } from "react-i18next";

interface IProps {
  onDismiss?: () => void;
}

let index = 0;

export function setCarouselIndex(newIndex: number) {
  index = newIndex;
}

export function ButtonActionPanel(props: IProps) {
  const { onDismiss } = props;
  const navigate = useNavigate();

  const { taskEntity, setShowDeleteDialog } = userTaskStores((state) => ({
    taskEntity: state.taskEntity,
    setShowDeleteDialog: state.setShowDeleteDialog,
  }));

  const { recoverCreateEntity } = useCreateStores((state) => ({
    recoverCreateEntity: state.recoverCreateEntity,
  }));

  const showCover = () => {
    if (
      taskEntity &&
      taskEntity.projectEntity &&
      taskEntity.projectEntity.cover_img
    ) {
      if (taskEntity.projectEntity.cover_img.length > 1) {
        return true;
      }
    } else {
      return false;
    }
  };

  const download = (url: string, filename: string) => {
    let downloadUrl = null;
    if (showCover()) {
      if (index === 0) {
        downloadUrl = taskEntity.projectEntity?.cover_img;
      } else {
        if (
          taskEntity &&
          taskEntity.projectEntity &&
          taskEntity.projectEntity.project_segments
        ) {
          downloadUrl =
            taskEntity.projectEntity.project_segments[index - 1].image_url;
        }
      }
    } else {
      if (
        taskEntity &&
        taskEntity.projectEntity &&
        taskEntity.projectEntity.project_segments
      ) {
        downloadUrl = taskEntity.projectEntity.project_segments[0].image_url;
      }
    }
    if (downloadUrl) {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = filename;
      link.click();
    }
  };

  const showDialog = () => {
    setShowDeleteDialog(true);
  };

  const gotoMyCreation = () => {
    if (onDismiss) {
      onDismiss();
    }
    navigate(`/my-creations`);
  };

  const gotoRegenerate = () => {
    if (taskEntity && taskEntity.projectEntity) {
      recoverCreateEntity(taskEntity.projectEntity);
    }
    navigate("/create", { state: { reCreateEntity: taskEntity } });
  };

  const copy = () => {
    navigator.clipboard.writeText(taskEntity.prompt);
    toast.success("Copy Success");
  };
  const { t } = useTranslation();

  return (
    <div className="flex absolute bottom-[0px] w-full  flex-col justify-start items-center bg-dark-bg h-[100px] p-1 lg:p-0">
      <div className="  flex flex-row justify-between w-full pt-[15px]  h-[70px]">
        <div className="text-white flex flex-row ">
          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              download(taskEntity.img_url, "a.png");
            }}
            className="bg-dark-bg flex-col flex w-[40px]  h-[40px] lg:h-[45px] justify-center items-center"
          >
            <FontAwesomeIcon size={"lg"} icon={faArrowDown} />
          </Button>
          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={(e) => {
              e.stopPropagation();
              showDialog();
            }}
            className="bg-dark-bg ml-[10px] flex-col flex w-[40px]  h-[40px] lg:h-[45px] justify-center items-center"
          >
            <FontAwesomeIcon size={"lg"} icon={faTrashCan} />
          </Button>
        </div>
        <div className="text-white flex flex-row">
          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={() => {
              gotoRegenerate();
            }}
            className="bg-dark-bg flex-col flex h-[40px] lg:h-[45px] justify-center  text-[14px]"
          >
            {t("recreate")}
          </Button>

          <Button
            placeholder={""}
            variant={"outlined"}
            onClick={() => {
              gotoMyCreation();
            }}
            className="bg-dark-bg ml-[10px] flex-col flex  h-[40px] lg:h-[45px] justify-center items-center text-[14px]"
          >
            {t("my_creation")}
          </Button>
        </div>
      </div>

      <div
        onClick={() => {
          copy();
        }}
        className="flex flex-col justify-between w-full h-[30px] cursor-pointer"
      >
        <div className="h-[1px] w-full bg-gray "></div>
        <div className="flex flex-row justify-start ">
          <div className="text-white text-ellipsis line-clamp-1 text-[13px]">
            {taskEntity.prompt}
          </div>
          <FontAwesomeIcon
            className="ml-[5px]"
            size={"sm"}
            color={"white"}
            icon={faCopy}
          />
        </div>
      </div>
    </div>
  );
}
