import React, { useEffect, useRef, useState } from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { LoadingDialog } from "../../components/create/LoadingDoialog";
import { FaceEditButtonActionPanel } from "../../components/face-edit/FaceEditButtonActionPanel";
import { DeleteDialog } from "../../components/task/DeleteDoialog";
import { TaskProcessBar } from "../../components/task/TaaskProcessBar";
import { FaceEditDemoShowDialog } from "./FaceEditDemoShowDialog";
import { ProjectStatusEnum } from "../../api/task/TaskApi";
import { useTranslation } from "react-i18next";
import { VideoInit } from "../../components/face-edit/VideoInit";
import { VideoFailed } from "../../components/face-edit/VideoFailed";

export function VideoContent() {
  const { t } = useTranslation();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [showDemoDialog, setShowDemoDialog] = useState(false);
  const {
    uploadingDialogTitle,
    showUploadingDialog,
    cancelUploadVideo,
    project,
    previewOriginVideo,
    setShowDeleteDialog,
    deleteTask,
    showDeleteDialog,
    deleting,
    videoCreateProcessing,
    getAwsUrl,
    updateUploadToAwsProcessingNum,
    estimatedRemainingTime,
  } = useFaceEditStores((state) => ({
    selectModeType: state.selectModeType,
    fetchTasks: state.fetchTasks,
    subGenVideoTask: state.subGenVideoTask,
    uploadFace: state.uploadFace,
    uploadingDialogTitle: state.uploadingDialogTitle,
    showUploadingDialog: state.showUploadingDialog,
    cancelUploadVideo: state.cancelUploadVideo,
    project: state.project,
    previewOriginVideo: state.previewOriginVideo,
    setShowDeleteDialog: state.setShowDeleteDialog,
    deleteTask: state.deleteTask,
    showDeleteDialog: state.showDeleteDialog,
    deleting: state.deleting,
    videoCreateProcessing: state.videoCreateProcessing,
    queryFaceEditTask: state.queryFaceEditTask,
    createEntity: state.createEntity,
    awsUrlEntity: state.awsUrlEntity,
    getAwsUrl: state.getAwsUrl,
    updateUploadToAwsProcessingNum: state.updateUploadToAwsProcessingNum,
    estimatedRemainingTime: state.estimatedRemainingTime,
  }));

  const cancelQuery = () => {
    cancelUploadVideo();
  };
  const showInit = () => {
    if (!project) {
      return true;
    } else {
      return false;
    }
  };

  const previewOriVideo = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments.length > 0 &&
      project.project_segments[0].origin_video_url
    ) {
      return project.project_segments[0].origin_video_url;
    }
    return "";
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load();
    }
  }, [previewOriVideo()]);

  const previewSwapVideo = () => {
    if (
      project &&
      project.project_segments &&
      project.project_segments.length > 0 &&
      project.project_segments[0].gen_video_url
    ) {
      return project.project_segments[0].gen_video_url;
    }
    return "";
  };
  const showPreview = () => {
    if (!project) {
      return false;
    } else {
      if (
        project &&
        project.project_segments &&
        project.project_segments.length > 0 &&
        project.project_segments[0].origin_video_url
      ) {
        return true;
      }
      return false;
    }
  };

  const hideDialog = () => {
    setShowDeleteDialog(false);
  };

  const handleConfirm = () => {
    deleteTask();
  };

  useEffect(() => {
    getAwsUrl();
  }, []);

  let time = (project && project.project_segments[0].estimated_time) || 30;
  time = time * 1000;

  const videoFailStatus = () => {
    const status_code = (project && project.project_status[0]) || 0;
    if (
      status_code == ProjectStatusEnum.SwapGenerateFailed ||
      status_code == ProjectStatusEnum.FileNotSupport ||
      status_code == ProjectStatusEnum.FileOverSize ||
      status_code == ProjectStatusEnum.VideoNotContainFaces
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className="select-none bg-dark-bg lg:w-1/2 w-full lg:h-full h-full lg:mx-2 lg:mt-0 lg:mb-0  mb-2   rounded-[15px] p-1 overflow-hidden relative">
      <LoadingDialog
        show={showUploadingDialog && false}
        title={uploadingDialogTitle}
        msg={""}
        onCancel={() => {
          cancelQuery();
        }}
      ></LoadingDialog>
      <DeleteDialog
        open={showDeleteDialog}
        onConfirm={() => {
          handleConfirm();
        }}
        onCancel={() => {
          hideDialog();
        }}
        deleting={deleting}
      ></DeleteDialog>
      <div
        className={`rounded-[10px] border-dashed  ${
          showInit() ? "border-2" : "border-0"
        } border-white flex flex-col justify-center w-full h-full`}
      >
        {!(videoCreateProcessing || showUploadingDialog) &&
          !videoFailStatus() &&
          showInit() && <VideoInit></VideoInit>}

        {!(videoCreateProcessing || showUploadingDialog) &&
          !videoFailStatus() &&
          showPreview() && (
            <div
              className={`h-full w-full flex flex-col justify-between overflow-hidden relative  `}
            >
              {previewOriginVideo && (
                <video
                  ref={videoRef}
                  className={`lg:w-full lg:h-[90%] h-full rounded-lg mb-[60px] pb-[60px]`}
                  controls
                >
                  <source src={previewOriVideo()} type="video/mp4" />
                </video>
              )}
              {!previewOriginVideo && (
                <video
                  key={previewSwapVideo()}
                  className={`lg:w-full lg:h-[90%] h-full rounded-lg mb-[60px] pb-[60px]`}
                  controls
                  autoPlay
                >
                  <source src={previewSwapVideo()} type="video/mp4" />
                </video>
              )}
              <div className={"w-full absolute bottom-0"}>
                {!videoFailStatus() && (
                  <FaceEditButtonActionPanel></FaceEditButtonActionPanel>
                )}
              </div>
            </div>
          )}

        {(videoCreateProcessing || showUploadingDialog) &&
          !videoFailStatus() && (
            <div className="h-full w-full left flex gap-8 flex-col items-center justify-center">
              <div className=" flex flex-col">
                <TaskProcessBar
                  duration={showUploadingDialog ? 0 : time}
                  processPercentage={
                    updateUploadToAwsProcessingNum
                      ? updateUploadToAwsProcessingNum
                      : 0
                  }
                />
                {videoCreateProcessing && (
                  <div className="text-white text-[22px] mt-[10px]">
                    {t("face_swapping_in_progress")}
                  </div>
                )}
                {showUploadingDialog && (
                  <div>
                    <div className="text-white text-[22px] mt-[10px]">
                      {t("uploading_in_progress")}
                    </div>
                    <div className="text-white text-[18px] mt-[10px]">
                      {t("estimated_time")}
                      {estimatedRemainingTime}
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

        {videoFailStatus() && <VideoFailed></VideoFailed>}

        <FaceEditDemoShowDialog
          show={showDemoDialog}
          msg={""}
          title={""}
          onCancel={function (): void {
            setShowDemoDialog(false);
          }}
        />
      </div>
    </div>
  );
}
