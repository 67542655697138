import {
  Button,
  Dialog,
  DialogBody,
  DialogFooter,
  DialogHeader,
  Spinner,
} from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";

interface IProps {
  show: boolean;
  msg: string;
  title: string;
  onCancel: () => void;
}

export function LoadingDialog(props: IProps) {
  const { show, msg, onCancel, title } = props;
  const { t } = useTranslation();
  return (
    <Dialog
      placeholder={""}
      open={show}
      handler={(e) => {}}
      className="bg-dark-bg"
    >
      <DialogHeader placeholder={""} className="text-white">
        <Spinner
          className="h-6 w-6 text-gray-900/50 mr-[10px]"
          color="purple"
        />
        {title}
      </DialogHeader>
      <DialogBody
        placeholder={""}
        className="text-white"
        children={undefined}
      ></DialogBody>
      <DialogFooter placeholder={""}>
        <Button
          placeholder={""}
          variant="text"
          color="red"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          className="mr-1 text-white"
        >
          <span>{t("cancel")}</span>
        </Button>
      </DialogFooter>
    </Dialog>
  );
}
