import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClapperboard, faFilm } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useState } from "react";
import { useFaceEditStores } from "../../stores/FaceEditStores";
import { useTranslation } from "react-i18next";
import { FaceEditDemoShowDialog } from "../../pages/face-edit/FaceEditDemoShowDialog";

export function VideoInit() {
  const fileInputRef = React.createRef<HTMLInputElement>();
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null);
  const [showDemoDialog, setShowDemoDialog] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    setInputRef(fileInputRef.current); // 使用 current 属性
  }, []);

  //把图片展示到前台
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      if (!["video/mp4"].includes(files[0].type)) {
        console.log("Only mp4 format  are allowed for upload.");
        return; // 终止执行
      }
      if (files[0].size > 500 * 1024 * 1024) {
        console.log("The file size cannot exceed 100MB, please choose again.");
        return; // 终止执行
      }
      uploadVideo(files[0]);
      console.log("上传视频");
      const localUrl = URL.createObjectURL(files[0]);
    }
  };

  const { uploadVideo, awsUrlEntity } = useFaceEditStores((state) => ({
    uploadVideo: state.uploadVideo,
    awsUrlEntity: state.awsUrlEntity,
  }));
  const clickUpload = () => {
    if (awsUrlEntity == null) {
      console.log("#clickUpload #awsUrlEntity is null");
      return;
    }

    fileInputRef.current?.click();
  };

  return (
    <div className={`flex flex-col justify-center items-center`}>
      <input
        id="upload_input"
        ref={fileInputRef}
        className="opacity-0 top-0 left-0 right-0 bottom-0"
        type="file"
        accept="MPEG-4"
        placeholder=""
        onChange={(e) => handleChange(e)}
      />
      <div className="text-white font-medium text-[22px] mt-[2px] lg:mt-[5px]">
        {t("swap_face_with_video")}
      </div>
      <div
        className="text-white font-medium text-[18px] mt-[2px] lg:mt-[5px]"
        onClick={() => {
          setShowDemoDialog(true);
        }}
      >
        <div className={"flex flex-row"}>
          <FontAwesomeIcon
            icon={faFilm}
            size={"lg"}
            color={"white"}
            className="pt-[2px]"
          ></FontAwesomeIcon>
          <div className={"ml-[5px]"}>{t("preview_effect")}</div>
        </div>
      </div>

      <div className="text-white-hint font-medium text-[18px] mt-[1px] lg:mt-[5px]">
        {t("maximum_500_mb")}
      </div>
      <FontAwesomeIcon
        size={"5x"}
        color={"white"}
        className="border-white p-[10px] mb-[5px] lg:mb-[20px] rounded-[10px]"
        icon={faClapperboard}
      ></FontAwesomeIcon>
      <div
        onClick={clickUpload}
        className={`w-120 h-50 rounded-full pl-6 pr-6 pt-3 pb-3 ${
          awsUrlEntity == null
            ? " bg-zinc-400"
            : "bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500"
        }   font-medium`}
      >
        <div className="text-white font-sans font-bold">
          {t("upload_video")}
        </div>
      </div>
      <div className="text-white-hint font-medium text-[18px] mt-[5px]">
        {t("step1_upload_video")}
      </div>
      <div className="text-white-hint font-medium text-[18px] mt-[5px]">
        {t("step2_add_face_and_swap")}
      </div>
      {
        <FaceEditDemoShowDialog
          show={showDemoDialog}
          msg={""}
          title={""}
          onCancel={function (): void {
            setShowDemoDialog(false);
          }}
        />
      }
    </div>
  );
}
