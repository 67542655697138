import { Button, Spinner } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import { useCreateStores } from "../../stores/CreateStores";
import { useEffect } from "react";
import { useUserStores } from "../../stores/UserStores";
import { logEvent } from "firebase/analytics";
import { analytics, SOURCE_ID_KEY } from "../../App";
import {
  EVENT_ACTION_CREATE_PROJECT,
  EVENT_PAGE_CREATE,
} from "../../util/Event";
import { useTranslation } from "react-i18next";

export function CreateButton() {
  const { userEntity } = useUserStores((state) => ({
    userEntity: state.userEntity,
  }));
  const {
    createEntity,
    createTask,
    gotoTask,
    creatingTask,
    resetState,
    addHistoryTag,
    gotoSubscribe,
  } = useCreateStores((state) => ({
    createEntity: state.createEntity,
    createTask: state.createTask,
    gotoTask: state.gotoTask,
    gotoSubscribe: state.gotoSubscribe,
    creatingTask: state.creatingTask,
    resetState: state.resetStatus,
    addHistoryTag: state.addHistoryTag,
  }));
  useEffect(() => {
    if (gotoTask) {
      navigate(
        `/task?id=${createEntity.project_id}&batch_size=${createEntity.batchNumber}`,
      );
      resetState();
    }
  }, [gotoTask]);

  useEffect(() => {
    if (gotoSubscribe) {
      navigate("/subscribe");
      resetState();
    }
  }, [gotoSubscribe]);

  const navigate = useNavigate();
  const create = () => {
    if (!userEntity) {
      navigate(`/login`, { state: { origin: "create_page" } });
      return;
    }
    if (!creatingTask) {
      createTask();
      const sourceId = localStorage.getItem(SOURCE_ID_KEY);
      logEvent(analytics, EVENT_ACTION_CREATE_PROJECT, {
        page_path: EVENT_ACTION_CREATE_PROJECT,
        source_id: sourceId,
      });
    }
  };
  const { t } = useTranslation();
  return (
    <Button
      placeholder={""}
      onClick={create}
      className="relative bg-gradient-to-r from-blue-500 to-pink-500 flex flex-row justify-center items-center text-[22px] bg-gray w-full  h-500 border-gray border-4 rounded-[30px] h-[60px] mt-[30px] "
    >
      <div className="flex gap-8">
        {creatingTask && (
          <Spinner
            className="h-6 w-6 text-gray-900/50 mr-[10px]"
            color="purple"
          />
        )}
      </div>
      <div>{t("create")}</div>
      <div className="absolute right-5 top-0 text-[12px] bg-white text-black pl-2 pr-2  rounded-bl-[10px] rounded-br-[10px] normal-case">
        {createEntity.batchNumber} {t("credit")}
      </div>
    </Button>
  );
}
