export interface FaceModel {
  id: string;
  index: number;
  user_id?: string;
  url: string;
  resize_url: string;
  detection_result: boolean;
  status?: [number, string];
}

export enum FaceModelStatus {
  "uncreate" = 0,
  "created" = 1,
  "uploaded" = 2,
  "face_detected" = 3,
}
