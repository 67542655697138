export const EVENT_PAGE_VIEW = "page";

export const EVENT_PAGE_SUBSCRIBE = "page_subscribe";
export const EVENT_PAGE_CREATE = "page_create";
export const EVENT_PAGE_TASK_DETAIL = "page_task_detail";
export const EVENT_PAGE_LOGIN = "page_login";
export const EVENT_PAGE_MY_CREATIONS = "page_my_creations";

export const EVENT_ACTION_CREATE_PROJECT = "action_create_project";
export const EVENT_ACTION_CREATE_PAYMENT = "action_create_subscribe";
export const EVENT_ACTION_PAYMENT_SUCCESS = "action_payment_success";
export const EVENT_ACTION_PAYMENT_FAILED = "action_payment_failed";
//用户上传视频PV
export const ACTION_FACE_EDIT_VIDEO_UPLOAD = "action_face_edit_video_upload";
//用户点击创作PV
export const ACTION_FACE_EDIT_CREATE = "action_face_edit_create";
//用户上传人脸PV
export const ACTION_FACE_EDIT_MODEL_UPLAOD = "action_face_edit_model_uplaod";

export const EVENT_PAGE_SOUL_CHAT = "page_soul_chat";
export const EVENT_ACTION_SOULMATES_CREATE = "action_soulmates_create";
export const EVENT_ACTION_SOULMATES_SEND_CHAT_MESSAGE = "action_soulmates_send_chat_message";
export const EVENT_ACTION_SOULMATES_SEND_PIC_MESSAGE = "action_soulmates_send_pic_message";
export const EVENT_ACTION_DELETE_SOULMATE = "action_delete_soulmate";

export const EVENT_ACTION_PAGE_AI_CHAT = "page_ai_chat";
